import React, { useState, useRef, useEffect } from 'react'
import {
    IconShareStoryNGN,
    FacebookIcon,
    XIcon,
    IconEmailNGN,
} from '@news-mono/component-library'
import { ShareOption, SharingEvent } from '@news-mono/web-common'
import { colorsPN } from '@news-mono/design-tokens'

import {
    StyledPNSharingWrapper,
    StyledShareOptionsPopover,
    StyledPopoverHeader,
    StyledShareOptionsWrapper,
    StyledShareButton,
    StyledButtonText,
} from './PNSharingStory.styled'
import { getButtonOnClick } from '../../../../../buttons/Sharing/utils/get-button-on-click'
import { useSectionMetaInfo } from '../../../../../__helpers/use-section-meta'

export interface SharingProps {
    url: string
    text: string
    shareOptions: ShareOption[]
    onEvent: (event: SharingEvent) => void
    openState?: boolean
}
export interface NGNSharingProps {
    handleShareClick: () => void
}

export const PNSharingStory: React.FC<SharingProps> = ({
    onEvent,
    shareOptions,
    url,
    text,
    openState,
}) => {
    const [open, setOpen] = useState(openState || false)
    const sectionMeta = useSectionMetaInfo()
    const shareOptionsWrapperRef = useRef<HTMLDivElement>(null)
    const popoverRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (
            popoverRef.current &&
            !popoverRef.current.contains(event.target as Node) &&
            shareOptionsWrapperRef.current &&
            !shareOptionsWrapperRef.current.contains(event.target as Node)
        ) {
            setOpen(false)
        }
    }

    const getButtonIcon = (shareOption: string) => {
        switch (shareOption.toLowerCase()) {
            case 'facebook':
                return (
                    <FacebookIcon
                        backgroundFillColor={colorsPN.text.secondary}
                    />
                )
            case 'twitter':
                return <XIcon backgroundFillColor={colorsPN.text.secondary} />
            case 'email':
                return <IconEmailNGN />
            default:
                return null
        }
    }
    const handleClick = (shareOption: ShareOption) => {
        onEvent({
            type: 'shareButton.click',
            originator: 'Sharing',
            payload: {
                shareType: shareOption,
            },
        })
        getButtonOnClick(shareOption, url, text, sectionMeta.FacebookAppId)()
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside)
            document.addEventListener('keydown', handleKeyDown)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleKeyDown)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [open])

    const getShareOptionsPopover = () => (
        <StyledShareOptionsPopover ref={popoverRef}>
            <StyledPopoverHeader>
                Share this story
                <IconShareStoryNGN shareFillColor={colorsPN.icon.primary} />
            </StyledPopoverHeader>
            {shareOptions.map((shareOption) => (
                <StyledShareButton
                    key={shareOption}
                    onClick={() => handleClick(shareOption)}
                >
                    {getButtonIcon(shareOption)}
                    <StyledButtonText>
                        {shareOption === 'twitter'
                            ? 'Twitter / X'
                            : shareOption}
                    </StyledButtonText>
                </StyledShareButton>
            ))}
        </StyledShareOptionsPopover>
    )

    return (
        <StyledShareOptionsWrapper ref={shareOptionsWrapperRef}>
            <StyledPNSharingWrapper
                aria-label={'Share this story'}
                onClick={() => setOpen((prev) => !prev)}
                open={open}
            >
                Share story
                <IconShareStoryNGN />
            </StyledPNSharingWrapper>
            {open && getShareOptionsPopover()}
        </StyledShareOptionsWrapper>
    )
}

PNSharingStory.displayName = 'PNSharingStory'

export default PNSharingStory

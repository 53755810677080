import { colorsPN } from '@news-mono/design-tokens'
import React, { useEffect, useState } from 'react'
import { IconNextGenPerthNowComment } from '../../../../../icons'
import {
    Comments,
    CommentsSpan,
    StyledIconWrapper,
} from './PNCommentsCount.styled'

export type CommentsProps = {
    articleUrl?: string
    size?: number
}

export const PNCommentsCount: React.FC<CommentsProps> = ({
    ...props
}: CommentsProps) => {
    const [showLoadedComments, setShowLoadedComments] = useState(false)
    useEffect(() => {
        if (!showLoadedComments) {
            setShowLoadedComments(true)
        } else {
            window.CoralCount && window.CoralCount.reload()
        }
    }, [showLoadedComments])

    return (
        <Comments to={'#coral_thread'}>
            {props.articleUrl && (
                <CommentsSpan
                    className="coral-count"
                    data-coral-url={props.articleUrl}
                    data-coral-notext={true}
                    dangerouslySetInnerHTML={{ __html: '' }} // Bypass hydration overwriting the span
                    suppressHydrationWarning
                ></CommentsSpan>
            )}
            <StyledIconWrapper>
                <IconNextGenPerthNowComment color={colorsPN.icon.brand} />
            </StyledIconWrapper>
        </Comments>
    )
}

export default PNCommentsCount

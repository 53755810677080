import React, { memo, useState, useCallback } from 'react'
import { AllEvents, Block } from '@news-mono/web-common'
import { Logger } from 'typescript-log'
import {
    StyledTabList,
    StyledTab,
    StyledTabGroupList,
    StyledTabGroup,
} from './PNTabs.styled'
import { ArticleBlockContent } from '../../../publication/ArticleBlockContent/ArticleBlockContent'

export interface Intention {
    href?: string
    index?: number
    length?: number
}

export interface PNTab {
    id?: string
    label: string
    content: Block[]
}

export interface PNTabsProps {
    tabs: PNTab[]
    onEvent: (event: AllEvents) => void
    innerRef?: React.RefObject<any>
    log: Logger
    publicationPathname?: string
    adUnitPath: string
    targetedContentElement?: React.ReactElement<any>
    defaultTab?: number
}

/**
 * Component for rendering a set of tabs.
 *
 * @param {PNTabsProps} props - The component props.
 * @param {Array<Tab>} props.tabs - The array of tabs to render.
 * @param {Function} props.onEvent - The event handler function.
 * @param {Logger} props.log - The logger instance.
 * @param {string} props.publicationPathname - The publication pathname.
 * @param {string} props.adUnitPath - The ad unit path.
 * @returns {JSX.Element} The rendered component.
 */
export const PNTabs: React.FC<PNTabsProps> = ({
    tabs,
    onEvent,
    log,
    publicationPathname,
    adUnitPath,
    targetedContentElement,
    defaultTab = 0,
}) => {
    const [selectedTab, setSelectedTab] = useState(defaultTab)
    const handleTabClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>, index: number) => {
            event.preventDefault()
            setSelectedTab(index)
        },
        [],
    )

    const handleKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLAnchorElement>, index: number) => {
            if (event.key === 'Enter') setSelectedTab(index)
        },
        [],
    )
    return (
        <section>
            <StyledTabList role="tablist">
                {tabs.map((tab, index) => (
                    <StyledTab
                        key={`tab-${tab?.label}`}
                        role="tab"
                        aria-selected={selectedTab === index}
                        tabIndex={0}
                        id={`tab-${tab?.label}`}
                        aria-controls={`panel-${index}`}
                        onClick={(event) => handleTabClick(event, index)}
                        onKeyPress={(event) => handleKeyPress(event, index)}
                        to={''}
                    >
                        {tab?.label}
                    </StyledTab>
                ))}
            </StyledTabList>
            <StyledTabGroupList role="group">
                {tabs.map((tab, index) => (
                    <StyledTabGroup
                        key={`panel-${index}`}
                        role="tabpanel"
                        aria-hidden={selectedTab !== index}
                        id={`panel-${index}`}
                        aria-labelledby={`tab-${tab?.label}`}
                        style={{
                            display: selectedTab === index ? 'block' : 'none',
                        }}
                    >
                        <ArticleBlockContent
                            key={`pn-tab-${index}`}
                            blocks={tab.content}
                            log={log}
                            onEvent={onEvent}
                            publicationPathname={publicationPathname}
                            adUnitPath={adUnitPath}
                            targetedContentElement={targetedContentElement}
                        />
                    </StyledTabGroup>
                ))}
            </StyledTabGroupList>
        </section>
    )
}

export default memo(PNTabs)

import React, { useState, useEffect, useCallback } from 'react'
import {
    StyledMenuContainer,
    StyledMenuList,
    StyledMenuItem,
    StyledMenuLink,
    StyledSubMenu,
    StyledSubMenuToggle,
    StyledIcon,
    StyledDivider,
} from './HamburgerMenu.styled'
import { PNNavLinkNGN } from '../PNNavigation'
import { AllEvents, DataLayerEventName } from '@news-mono/web-common'

interface HamburgerMenuProps {
    navLinks: PNNavLinkNGN[]
    isOpen: boolean
    toggleMenu: () => void
    currentSection: string
    onEvent: (event: AllEvents) => void
    isSnapshot?: boolean
}

export const HamburgerMenu: React.FC<HamburgerMenuProps> = React.memo(
    ({ navLinks, isOpen, currentSection, isSnapshot = false, onEvent }) => {
        const [openSubMenus, setOpenSubMenus] = useState<{
            [key: string]: boolean
        }>({})

        const extraMenuItems = [
            { link: '/about-us', text: 'About PerthNow' },
            { link: '/contact-us', text: 'Contact Us' },
            {
                link: '/local-news/find-my-paper',
                text: 'Printed paper locations',
            },
            { link: '/editions', text: 'Digital editions' },
        ]

        const toggleSubMenu = useCallback((link: string) => {
            setOpenSubMenus((prevState) => ({
                ...prevState,
                [link]: !prevState[link],
            }))
        }, [])

        useEffect(() => {
            if (isOpen) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
            return () => {
                document.body.style.overflow = 'auto'
            }
        }, [isOpen])

        const renderMenuItems = (
            links: PNNavLinkNGN[],
            parentKey = '',
            level = 0,
        ) => {
            return links.map((link) => {
                const key = parentKey ? `${parentKey}-${link.link}` : link.link
                return (
                    <StyledMenuItem
                        key={key}
                        onClick={() => toggleSubMenu(key)}
                    >
                        <StyledMenuLink
                            href={link.link}
                            onClick={() => {
                                if (onEvent) {
                                    onEvent({
                                        type: DataLayerEventName.navClicked,
                                        originator: 'HamburgerMenu',
                                        payload: {
                                            navName: `Flyout.PerthNow`,
                                            navLocation: `PerthNow.PrimaryNav`,
                                            navText: link.name,
                                            navLink: link.link,
                                        },
                                    })
                                }
                            }}
                            aria-label={link.name}
                            isOpen={
                                currentSection.toLowerCase() ===
                                link.name.toLowerCase()
                            }
                        >
                            {link.name}
                        </StyledMenuLink>
                        {link.subNavLinks && link.subNavLinks.length > 0 && (
                            <>
                                <StyledSubMenuToggle
                                    aria-expanded={openSubMenus[key]}
                                    aria-label={`Toggle submenu for ${link.name}`}
                                >
                                    {level === 0 && (
                                        <StyledIcon
                                            isOpen={openSubMenus[key]}
                                        />
                                    )}
                                </StyledSubMenuToggle>
                                <StyledSubMenu isOpen={openSubMenus[key]}>
                                    {link?.subNavLinks &&
                                        level < 1 &&
                                        renderMenuItems(
                                            link.subNavLinks as PNNavLinkNGN[],
                                            key,
                                            level + 1,
                                        )}
                                </StyledSubMenu>
                            </>
                        )}
                    </StyledMenuItem>
                )
            })
        }

        const renderExtraMenuItems = () => {
            return extraMenuItems.map((link) => {
                return (
                    <StyledMenuItem>
                        <StyledMenuLink
                            href={link.link}
                            onClick={() => {
                                if (onEvent) {
                                    onEvent({
                                        type: DataLayerEventName.navClicked,
                                        originator: 'HamburgerMenu',
                                        payload: {
                                            navName: `Flyout.PerthNow`,
                                            navLocation: `PerthNow.PrimaryNav.ExtraItems`,
                                            navText: link.text,
                                            navLink: link.link,
                                        },
                                    })
                                }
                            }}
                        >
                            {link.text}
                        </StyledMenuLink>
                    </StyledMenuItem>
                )
            })
        }

        return (
            <StyledMenuContainer
                isOpen={isOpen}
                aria-hidden={!isOpen}
                isSnapshot={isSnapshot}
            >
                <StyledMenuList>{renderMenuItems(navLinks)}</StyledMenuList>
                <StyledDivider />
                <StyledMenuList>{renderExtraMenuItems()}</StyledMenuList>
            </StyledMenuContainer>
        )
    },
)

import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../../../__styling'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../../mixins'
const { spacing } = metricsPN
export const StyledPNSharingWrapper = styled('button')({
    ...perthNowNGNTextMixin.label.M.semibold,
    minWidth: calcRem(115),
    backgroundColor: colorsPN.fill.brand.weak,
    padding: calcRem(spacing['2XS'], spacing['XS']),
    border: 'none',
    borderRadius: calcRem(spacing['2XS']),
    color: colorsPN.text.brand,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    svg: {
        marginLeft: calcRem(spacing['2XS']),
    },
    /* TODO:
     * - Hover: Changes background color to `#FCEDF2` (needs to be added to design tokens).
     * - Pressed: Changes background color to `#FCE6EE` (needs to be added to design tokens).
     */
    '&:hover': {
        backgroundColor: '#FCEDF2',
    },
    '&:active': {
        color: colorsPN.text.pressed,
        backgroundColor: '#FCE6EE',
    },
    '&:focus-visible': {
        outlineColor: colorsPN.text.brand,
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
})

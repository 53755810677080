import React from 'react'
import { Topic, toTopicRouteHint } from '@news-mono/web-common'
import { StyledTag } from './PNTag.styled'

export interface TagProps {
    topic: Topic
}

export const PNTag: React.FC<TagProps> = ({ topic }) => {
    return <StyledTag to={toTopicRouteHint(topic)}>{topic.title}</StyledTag>
}

PNTag.displayName = 'Tag'

import styled from '@emotion/styled'
import { withClass, WebLink } from '@news-mono/web-common'
import {
    calcRem,
    breakpoint,
    breakpointMax,
    easing,
} from '@news-mono/component-library'
import { Link } from 'react-router-dom'
import { AdPlaceholder } from '../../../advertising/AdPlaceholder/AdPlaceholder'
import { PerthNowLogoHorizontal } from '../../../logos/PerthNowLogo/PerthNowLogo'
import { TheWestLogo } from '../../../logos/TheWestLogo/TheWestLogo'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'

const headerBreakpoint = breakpoint('lg')

export const styledPerthNowLogoClassName = 'PNHeader-PerthNowLogo' as any
export const styledWestAustralianLogoClassName = 'PNHeader-TheWestLogo' as any

export const StyledPNHeader = styled('header')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flexWrap: 'wrap',
    [headerBreakpoint]: {
        height: 'auto',
        flexWrap: 'nowrap',
    },
})
const logoWrapBaseStyles = {
    width: calcRem(114.72),
}
export const StyledHeaderRow = styled('div')({
    maxWidth: calcRem(metricsPN.layout.site.mainContentWidth),
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: calcRem(metricsPN.spacing['2XS'], 0),
})
export const StyledHeaderShadowWrap = styled('div')({
    boxShadow: `0px -10px 10px -10px ${colorsPN.shadow.blurs35}`,
    transition: `opacity 1s ${easing.easeOut.fast}`,
})
export const StyledHeaderBorderWrap = styled('div')<{
    mobileNavExpanded: boolean
}>(({ mobileNavExpanded }) => ({
    paddingTop: mobileNavExpanded ? calcRem(116) : 0,
    borderTop: `1px solid ${colorsPN.stroke.weak}`,
    [breakpointMax('md')]: {
        width: '100%',
    },
    [breakpointMax('xs')]: {
        paddingTop: mobileNavExpanded ? calcRem(27.5) : 0,
    },
}))
// Layout setup
export const StyledHeaderGrid = styled('div')<{
    isBreakpointWrap: boolean
}>(({ isBreakpointWrap = false }) => ({
    display: 'flex',
    maxWidth: !isBreakpointWrap
        ? '100vw'
        : calcRem(metricsPN.layout.site.mainContentWidth),
    margin: '0 auto',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    backgroundColor: colorsPN.background.base,
    borderBottom: `1px solid ${colorsPN.stroke.weak}`,
    ...(isBreakpointWrap
        ? {
              flexWrap: 'nowrap',
              width: 'auto',
              padding: calcRem(0, metricsPN.spacing['L']),
              minHeight: calcRem(64),
          }
        : {
              flexWrap: 'wrap',
              width: '100%',
              padding: 0,
          }),
}))

export const StyledHeaderColumn = styled('div')<{
    isBreakpointWrap: boolean
}>(({ isBreakpointWrap = false }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    ...(isBreakpointWrap
        ? {
              flex: `0 0 ${calcRem(200)}`,
              width: calcRem(200),
          }
        : {
              width: '100%',
              padding: calcRem(
                  0,
                  metricsPN.spacing['4XS'],
                  0,
                  metricsPN.spacing['S'],
              ),
          }),
    '&:focus-visible': {
        outline: `2px solid ${colorsPN.stroke.brand.strong}`,
        borderRadius: `${metricsPN.radius['S']}px`,
    },
}))
export const StyledHeaderColumnAd = styled('div')({
    width: '100%',
    margin: '0 auto',
    alignItems: 'stretch',
})
export const StyledLogoWrapHeading = styled('h1')(logoWrapBaseStyles, {
    margin: 0,
    position: 'relative',
    width: 'auto',
})
export const StyledTWLogoWrapHeading = styled('h2')(logoWrapBaseStyles, {
    margin: 0,
})
export const StyledLogoWrapLink = styled(Link)(logoWrapBaseStyles, {
    display: 'block',
})
export const StyledPerthNowLogo = withClass(styledPerthNowLogoClassName)(
    styled(PerthNowLogoHorizontal)({
        display: 'block',
        width: calcRem(136),
        height: calcRem(24),
        boxSizing: 'content-box',
        [headerBreakpoint]: {
            width: calcRem(200),
            height: calcRem(35.2),
        },
    }),
)

export const StyledTheWestLogo = withClass(styledWestAustralianLogoClassName)(
    styled(TheWestLogo)({
        display: 'block',
        width: calcRem(114.72),
        height: calcRem(10),
        boxSizing: 'content-box',
    }),
)
export const StyledTheWestLink = styled(WebLink)({
    '&:focus-visible': {
        outlineColor: colorsPN.stroke.brand.strong,
        outlineWidth: '2px',
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
        borderRadius: `${metricsPN.radius['S']}px`,
    },
})
export const StyledHamburgerButton = styled('button')({
    background: colorsPN.background.base,
    padding: calcRem(metricsPN.spacing['2XS'], metricsPN.spacing['XS']),
    width: calcRem(48),
    height: calcRem(48),
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:focus-visible': {
        outline: `2px solid ${colorsPN.stroke.brand.strong}`,
        borderRadius: `${metricsPN.radius['S']}px`,
    },
})
export const StyledPNHeaderWrapper = styled('div')({
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    // Provide space for hamburger especially focussed
    gap: calcRem(2),
})
export const StyledPNHeaderLoginWrapperMobile = styled('div')({
    height: 'auto',
})
export const StyledPNHeaderLoginWrapperDesktop = styled('div')({
    display: 'none',
    [headerBreakpoint]: {
        display: 'flex',
        width: 'auto',
    },
})
export const StyledScroll = styled('div')({
    overflowX: 'auto',
    width: '100%',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
})
export const StyledLeaderboard = styled(AdPlaceholder)({
    display: 'none',
    [headerBreakpoint]: {
        margin: '0 auto',
        display: 'block',
    },
})

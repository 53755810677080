import { ChangeEvent, useState, useRef, useEffect } from 'react'
import { handleUnknownError } from '@news-mono/common'
import {
    AllEventTypes,
    DataLayerEventName,
    AllEvents,
    NewsletterSignupEvent,
    NewsletterInteractionType,
    NewsletterSignupArgs,
    NewsletterSignupInteractArgs,
    NewsletterVariant,
} from '@news-mono/web-common'

import { useRecaptcha } from '../../../recaptcha/Recaptcha'

interface NewsletterSubscriptionFormState {
    email: string
    error: boolean
    success: boolean
    isSubmitting: boolean
    isSubscribed: string
}

const fireEvent = (
    onEvent: (event: AllEvents) => void,
    eventType: AllEventTypes,
    originator: NewsletterVariant,
    payload: NewsletterSignupInteractArgs | NewsletterSignupArgs,
) => {
    onEvent({
        type: eventType,
        originator,
        payload,
    } as NewsletterSignupEvent)
}

const handleInteractionEvent = (
    onEvent: (event: AllEvents) => void,
    originator: NewsletterVariant,
    interactionType: NewsletterInteractionType,
) => {
    fireEvent(
        onEvent,
        DataLayerEventName.newsletterSignupInteract,
        originator,
        {
            newsletterVariant: originator,
            interactionType,
        },
    )
}

const fireNewsletterEvent = (
    onEvent: (event: AllEvents) => void,
    originator: NewsletterVariant,
    type:
        | DataLayerEventName.newsletterSignupFail
        | DataLayerEventName.newsletterSignupSuccess,
) => {
    fireEvent(onEvent, type, originator, {
        newsletterVariant: originator,
    })
}

export const useNewsletterFormInput = (
    onEvent: (event: AllEvents) => void,
    originator: NewsletterVariant,
) => {
    const message = useRef('')
    const [formState, setFormState] = useState<NewsletterSubscriptionFormState>(
        {
            email: '',
            error: false,
            success: false,
            isSubmitting: false,
            isSubscribed: '',
        },
    )
    const { email, error, isSubscribed } = formState
    const { executeRecaptcha } = useRecaptcha()

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        // Copied from content api
        const EMAIL_REGEX =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isValidEmail = EMAIL_REGEX.test(email)
        const isError = !email || !isValidEmail

        message.current = isError
            ? 'Please enter a complete email address.'
            : ''

        setFormState({
            ...formState,
            error: isError,
            isSubmitting: !isError,
        })

        if (isError) {
            fireNewsletterEvent(
                onEvent,
                originator,
                DataLayerEventName.newsletterSignupFail,
            )
            return
        }
        const token = await executeRecaptcha({ action: 'subscribe' })
        try {
            const response = await fetch('/newsletter-subscribe', {
                method: 'POST',
                body: JSON.stringify({
                    token,
                    email: email,
                    firstName: email,
                    lastName: '',
                    source: 'PerthNow Newsletter Banner',
                    requestToken: true,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.ok) {
                setFormState({
                    ...formState,
                    success: true,
                    isSubmitting: false,
                    isSubscribed: 'submitted',
                })
                fireNewsletterEvent(
                    onEvent,
                    originator,
                    DataLayerEventName.newsletterSignupSuccess,
                )
            } else {
                setFormState({
                    ...formState,
                    isSubmitting: false,
                    error: true,
                })

                throw new Error(
                    `NewsletterSignupBanner submission error: ${response.status} ${response.statusText}`,
                )
            }
        } catch (error) {
            const err = handleUnknownError(error)
            fireNewsletterEvent(
                onEvent,
                originator,
                DataLayerEventName.newsletterSignupFail,
            )
            console.error(err)
        }
    }

    const handleOnChange = (e: ChangeEvent<any>) => {
        setFormState({
            ...formState,
            email: e.currentTarget.value,
        })
    }

    const handleFocus = () => {
        handleInteractionEvent(onEvent, originator, 'email-address')
    }

    const handleOnClick = () => {
        fireNewsletterEvent(
            onEvent,
            originator,
            DataLayerEventName.newsletterSignupFail,
        )
    }

    const handleFormReset = () => {
        message.current = ''
        setFormState({
            email: '',
            error: false,
            success: false,
            isSubmitting: false,
            isSubscribed,
        })
    }

    return {
        handleSubmit,
        handleOnChange,
        handleFocus,
        handleOnClick,
        handleFormReset,
        email,
        error,
        message,
        isSubscribed,
    }
}

export type NewsletterFormInputHook = ReturnType<typeof useNewsletterFormInput>

import React from 'react'

export const IconCheckNGN = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M20 6L9 17L4 12"
            stroke="#DD1466"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
IconCheckNGN.displayName = 'IconCheckNGN'

import React from 'react'
import {
    CardEvents,
    CollectionEvent,
    ContentDataTypes,
    DataLayerEventName,
    generateViewItemCardInfo,
    mapListPublication,
    PublicationCardItem,
} from '@news-mono/web-common'
import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'
import { PerthNowSection, useProduct } from '@news-mono/component-library'
import {
    StyledLatestStoriesWrapper,
    StyledTopicHeader,
    StyledLatestStoriesList,
    StyledLatestStoriesLink,
} from './SecondaryMenuContainer.styled'
import { ImpressionAvailable } from '../../../../../__helpers/impression-available-helper'

export interface LatestStoriesProps {
    parent: {
        name: string
        link: string
        section: PerthNowSection
        forceInternalSSR?: boolean
    }
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    onEvent: (event: CardEvents | CollectionEvent) => void
    latestStories: ListPublicationV4DTO[]
    tabIndex: number
}

export const getLatestStoriesContext = (
    publications: PublicationCardItem[],
    parent: {
        name: string
        link: string
        section: PerthNowSection
        forceInternalSSR?: boolean
    },
) => {
    const latestStoriesContext = {
        timestamp: Date.now(),
        componentType: 'latest-stories-listing',
        componentRenderPath: '',
        dataDefinition: {
            type: 'listing',
            topics: [parent.link.substring(0)],
            paging: {
                page: 1,
                pageSize: 3,
            },
            total: publications.length,
        } as ContentDataTypes,
        collectionCardCount: publications.length,
    }
    return latestStoriesContext
}

export const LatestStories: React.FC<LatestStoriesProps> = ({
    parent,
    isOpen,
    setIsOpen,
    onEvent,
    latestStories,
    tabIndex,
}) => {
    const { name } = parent
    const linkTabIndex = isOpen ? tabIndex : -1
    const publications = latestStories.map((story) => {
        return mapListPublication(story)
    })
    const product = useProduct()
    const context = getLatestStoriesContext(publications, parent)

    // Helper that fires the select_item list event
    const linkClicked = (article: PublicationCardItem, index: number) => {
        setIsOpen(false)
        onEvent({
            context,
            type: DataLayerEventName.selectItemEvent,
            originator: 'PerthNow.MegaMenu.LatestStories',
            payload: {
                index: 0,
                item_id: article.id,
                item_brand: article.source,
                item_category: article.primaryTopic.id,
                item_category2: article.publicationKind,
                item_name: article.headline,
                item_heading: article.shortHeadline,
                price: 0,
                link_text: article.shortHeadline,
                link_url: article.link,
                item_variant: false,
            },
        })
    }
    return (
        <ImpressionAvailable
            loading={!isOpen}
            available={() => {
                if (!isOpen) {
                    console.warn(
                        'Available should never be called when mega menu is closed',
                    )
                    return
                }
                onEvent({
                    context,
                    type: DataLayerEventName.viewItemListEvent,
                    originator: 'PerthNow.MegaMenu.LatestStories',
                    payload: {
                        cardInformation: generateViewItemCardInfo(
                            publications,
                            product,
                        ),
                    },
                })
            }}
        >
            {(ref) => (
                <StyledLatestStoriesWrapper
                    tabIndex={-1}
                    aria-label="Latest Stories Section"
                    ref={ref}
                >
                    <StyledTopicHeader margin={[0, 0, 32, 0]}>
                        Latest stories
                    </StyledTopicHeader>
                    <StyledLatestStoriesList aria-label="Latest Stories List">
                        {Array.isArray(latestStories) &&
                            publications.map(
                                (
                                    article: PublicationCardItem,
                                    index: number,
                                ) => (
                                    <li key={`${name}${index}`} tabIndex={-1}>
                                        <StyledLatestStoriesLink
                                            onClick={() =>
                                                linkClicked(article, index)
                                            }
                                            to={article.link}
                                            tabIndex={linkTabIndex}
                                            aria-label={article?.shortHeadline}
                                        >
                                            {article?.shortHeadline}
                                        </StyledLatestStoriesLink>
                                    </li>
                                ),
                            )}
                    </StyledLatestStoriesList>
                </StyledLatestStoriesWrapper>
            )}
        </ImpressionAvailable>
    )
}

import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { Link } from 'react-router-dom'
import { perthNowNGNTextMixin } from '../../../../mixins'
import { calcRem } from '../../../../__styling'

export const StyledTag = styled(Link)({
    ...perthNowNGNTextMixin.label['M'].regular,
    display: 'inline-block',
    textDecoration: 'none',
    position: 'relative',
    color: colorsPN.text.brand,
    marginRight: calcRem(metricsPN.spacing['2XS']),
    padding: calcRem(metricsPN.spacing['3XS'], 0),

    '&:hover, &:focus': {
        WebkitTapHighlightColor: 'transparent',
    },

    '&:hover': {
        textDecoration: 'underline',
        outline: 'none',
    },

    '&:active': {
        color: colorsPN.text.pressed,
    },

    '&:focus-visible': {
        outlineColor: colorsPN.stroke.brand.strong,
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
})

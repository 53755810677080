import React from 'react'
import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { metrics, calcRem } from '@news-mono/component-library'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../../mixins/TextMixin/TextMixin.theme'
export interface MarginTopProp {
    margin: [number, number, number, number]
}
export interface StyledInputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    hasError?: boolean
}

interface StyledModalWrapperProps {
    isOpen: boolean
    isSnapshot: boolean
}

export const StyledModalWrapper = styled('li')<StyledModalWrapperProps>(
    ({ isOpen, isSnapshot }) => ({
        width: '100%',
        minHeight: calcRem(520),
        position: isSnapshot ? 'unset' : 'absolute',
        left: 0,
        top: calcRem(174),
        padding: calcRem(metricsPN.spacing['XS'], 0, metricsPN.spacing['L']),
        background: colorsPN.background.base,
        display: isOpen ? 'block' : 'none',
        zIndex: 2,
        boxShadow: `0px 10px 10px -10px ${colorsPN.shadow.blurs25}`,
    }),
)
export const StyledModal = styled('section')({
    width: '100%',
    maxWidth: calcRem(metrics.perthnow.siteMetrics.mainContentWidthNGN),
    height: 'fit-content',
    margin: '0 auto',
    padding: calcRem(0, metricsPN.spacing['L']),
})

export const StyledHeaderLink = styled(WebLink)({
    textDecoration: 'none',

    '&:focus-visible': {
        outlineColor: colorsPN.stroke.brand.strong,
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
})

export const StyledHeader = styled('h2')({
    ...perthNowNGNTextMixin.title.L.bold,
    color: colorsPN.text.brand,
    display: 'inline-block',
    margin: calcRem(0, 0, metricsPN.spacing['2XL']),
    '&:hover': {
        outline: 'none',
        textDecoration: 'underline',
        textDecorationColor: colorsPN.text.brand,
        textUnderlineOffset: calcRem(metricsPN.spacing['2XS']),
        textDecorationThickness: calcRem(2),
    },
})

export const StyledTopicHeader = styled('h3')<MarginTopProp>(
    ({ margin: [pt, pr, pb, pl] }) => ({
        ...perthNowNGNTextMixin.title.XS.bold,
        color: colorsPN.text.primary,
        margin: calcRem(pt, pr, pb, pl),
    }),
)

export const StyledLatestStoriesWrapper = styled('div')({
    width: '100%',
    padding: calcRem(0, 0, 0, metricsPN.spacing['XL']),
})

export const StyledLatestStoriesList = styled('ul')({
    display: 'block',
    padding: calcRem(0, metricsPN.spacing['XL'], 0, 0),
    margin: 0,
    listStyle: 'none',
    '& > li': {
        margin: calcRem(0, 0, metricsPN.spacing['L']),
    },
})

export const StyledLatestStoriesLink = styled(WebLink)({
    ...perthNowNGNTextMixin.label.L.medium,
    color: colorsPN.text.primary,
    textDecoration: 'none',
    padding: calcRem(metricsPN.spacing['3XS'], 0),
    '&:hover': {
        outline: 'none',
        textDecoration: 'underline',
        textDecorationColor: colorsPN.text.primary,
        textUnderlineOffset: calcRem(metricsPN.spacing['4XS']),
        textDecorationThickness: calcRem(2),
    },
    '&:focus-visible': {
        outlineColor: colorsPN.stroke.brand.strong,
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
    '&:active': {
        color: colorsPN.text.pressed,
    },
})
export const StyledAllThingHeader = styled('h4')({
    width: calcRem(243),
    padding: calcRem(0, 0, 0, metricsPN.spacing['XL']),
})
export const StyledSubNavContainer = styled('nav')({
    margin: 0,
    display: 'flex',
    '& > ul': {
        width: calcRem(389),
    },
    '& > div': {
        paddingLeft: calcRem(metricsPN.spacing['XL']),
    },
    '& > :nth-child(2)': {
        borderRight: `1px solid ${colorsPN.stroke.brand.weak}`,
        borderLeft: `1px solid ${colorsPN.stroke.brand.weak}`,
    },
})

export const StyledSubscribeLinksWrapper = styled('div')({
    minWidth: calcRem(408),
    padding: calcRem(0, 0, 0, metricsPN.spacing['XL']),
})
export const StyledSubscribeSpan = styled.span({
    ...perthNowNGNTextMixin.body.M.regular,
    color: colorsPN.text.primary,
})
export const StyledForm = styled.form({
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    margin: calcRem(metricsPN.spacing['XS'], 0),
})
export const StyledInputContainer = styled('div')({
    position: 'relative',
    width: '100%',
    marginRight: calcRem(metricsPN.spacing['2XS']),
})
export const StyledFloatingLabel = styled('label')({
    position: 'absolute',
    top: calcRem(metricsPN.spacing['XS']),
    left: calcRem(metricsPN.spacing['XS']),
    pointerEvents: 'none',
    ...perthNowNGNTextMixin.label.M.regular,
    color: colorsPN.text.primary,
})

export const StyledInput = styled('input')<StyledInputProps>(
    ({ hasError }) => ({
        ...perthNowNGNTextMixin.label.L.regular,
        color: hasError ? colorsPN.text.error : colorsPN.text.primary,
        minWidth: calcRem(221),
        width: '100%',
        height: calcRem(48),
        padding: hasError
            ? calcRem(
                  metricsPN.spacing['S'],
                  metricsPN.spacing['2XL'],
                  metricsPN.spacing['XS'],
                  metricsPN.spacing['XS'],
              )
            : calcRem(
                  metricsPN.spacing['S'],
                  metricsPN.spacing['XS'],
                  metricsPN.spacing['XS'],
              ),
        border: `1px solid ${
            hasError ? colorsPN.stroke.error : colorsPN.stroke.weak
        }`,
        borderRadius: calcRem(metricsPN.radius['S']),
        '&:focus-visible': {
            outline: 'none',
            border: `2px solid ${
                hasError ? colorsPN.stroke.error : colorsPN.stroke.strong
            }`,
        },
        '&:focus-visible + label, &:not(:placeholder-shown) + label': {
            top: calcRem(metricsPN.spacing['5XS']),
            left: calcRem(metricsPN.spacing['XS']),
            ...perthNowNGNTextMixin.label.XS.regular,
            color: hasError ? colorsPN.text.error : colorsPN.text.secondary,
        },
        '&::placeholder': {
            color: 'transparent',
        },
    }),
)
export const StyledErrorMessage = styled('div')({
    ...perthNowNGNTextMixin.label.S.regular,
    color: colorsPN.text.error,
    marginTop: calcRem(metricsPN.spacing['2XS']),
    whiteSpace: 'nowrap',
})
export const StyledErrorIcon = styled.span({
    position: 'absolute',
    display: 'flex',
    right: calcRem(metricsPN.spacing['XS']),
    top: calcRem(metricsPN.spacing['L']),
    transform: 'translateY(-50%)',
    '& svg path': {
        stroke: colorsPN.fill.error.strong,
    },
})
export const StyledButton = styled('button')({
    ...perthNowNGNTextMixin.label.L.semibold,
    textTransform: 'uppercase',
    width: calcRem(121),
    height: calcRem(44),
    textAlign: 'center',
    margin: calcRem(metricsPN.spacing['5XS'], 0),
    backgroundColor: colorsPN.background.base,
    color: colorsPN.text.brand,
    border: `1px solid ${colorsPN.stroke.brand.strong}`,
    borderRadius: calcRem(metricsPN.radius['L']),
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: colorsPN.fill.hover.brand,
        outline: 'none',
    },
    '&:focus-visible': {
        outlineColor: colorsPN.stroke.brand.strong,
        outlineOffset: calcRem(metricsPN.spacing['4XS']),
    },
})

export const StyledLink = styled(WebLink)({
    display: 'inline-block',
    ...perthNowNGNTextMixin.label.M.medium,
    color: colorsPN.text.primary,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
        outline: 'none',
        textDecoration: 'underline',
        textDecorationColor: colorsPN.text.primary,
        textUnderlineOffset: calcRem(metricsPN.spacing['4XS']),
        textDecorationThickness: calcRem(2),
    },
    '&:focus-visible': {
        outlineColor: colorsPN.stroke.brand.strong,
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
    },
    '&:active': {
        color: colorsPN.text.pressed,
    },
})
export const StyledSubscribeSuccessSpan = styled('div')({
    ...perthNowNGNTextMixin.label.M.regular,
    color: colorsPN.text.primary,
    padding: calcRem(metricsPN.spacing['2XS'], 0),
})

export const StyledDivWrapper = styled('div')({
    marginBottom: calcRem(metricsPN.spacing['L']),
})

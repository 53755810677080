import { colorsPN } from '@news-mono/design-tokens'
import { layout } from '../../App.routing'

interface Options {
    pageSize: number
    offset?: number
}

export const sidebarCollectionYouMightLike = (
    options: Options,
    topics: string[],
) =>
    layout.component({
        type: 'romeo',
        props: {
            removeHorizontalGutters: true,
            sectionHeader: {
                heading: 'You might like',
            },
            cardSpacing: 20,
            fontScale: 'XS',
            hideCardFooter: true,
            showCardTopic: true,
            headerSectionTag: 'h4',
            centreOnTablet: true,
            horizontalDividerColor: colorsPN.stroke.weak,
            dataDefinitionArgs: {
                type: 'listing',
                topics: topics,
                publicationKind: 'article',
                paging: {
                    page: 1,
                    offset: options.offset ? options.offset : 0,
                    pageSize: options.pageSize,
                },
            },
        },
    })

export const sidebarCollectionCommunityNews = (options: Options) =>
    layout.component({
        type: 'romeo',
        props: {
            removeHorizontalGutters: true,
            cardSpacing: 20,
            fontScale: 'XS',
            hideCardFooter: true,
            showCardTopic: true,
            headerSectionTag: 'h4',
            centreOnTablet: true,
            horizontalDividerColor: colorsPN.stroke.weak,
            sectionHeader: {
                heading: `Don't miss`,
            },
            dataDefinitionArgs: {
                type: 'curation',
                name: 'community-news-dont-miss',
                offset: options.offset ? options.offset : 0,
                pageSize: options.pageSize,
            },
        },
    })

export const sidebarCollectionShopNow = (options: Options) =>
    layout.component({
        type: 'romeo',
        props: {
            removeHorizontalGutters: true,
            cardSpacing: 20,
            fontScale: 'XS',
            hideCardFooter: true,
            showCardTopic: true,
            headerSectionTag: 'h4',
            centreOnTablet: true,
            horizontalDividerColor: colorsPN.stroke.weak,
            sectionHeader: {
                heading: 'Shop more',
                headingUrl: '/shop-now',
            },
            dataDefinitionArgs: {
                type: 'curation',
                name: 'shop-now',
                offset: options.offset ?? 0,
                pageSize: options.pageSize,
            },
        },
    })

import React from 'react'
import { StyledFootnote } from './PNFootnote.styled'
import { TextLink } from '../../../../typography/TextLink/TextLink'

export interface Link {
    text: string
    href: string
    className?: string
}

export interface FootnoteProps {
    text: string
    link?: Link
}

export const PNFootnote: React.FC<FootnoteProps> = ({ text, link }) => {
    return (
        <StyledFootnote>
            {text}
            {link && (
                <TextLink href={link.href} className={link.className}>
                    {link.text}
                </TextLink>
            )}
        </StyledFootnote>
    )
}
PNFootnote.displayName = 'PNFootnote'

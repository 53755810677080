import { shuffleArray } from '@news-mono/common'
import { Topic } from '@news-mono/web-common'
import React from 'react'
import { PNTag } from '../../buttons/PNTag/PNTag'
import {
    StyledText,
    StyledTopicListContainer,
    StyledTopicNav,
} from './PNTopicList.styled'

export interface TopicListProps {
    primaryTopic?: Topic
    secondaryTopics?: Topic[]
}

export const PNTopicList: React.FC<TopicListProps> = ({
    primaryTopic,
    secondaryTopics = [],
}) => {
    if (!primaryTopic && secondaryTopics.length === 0) {
        return null
    }

    let secondaryTopicsList = secondaryTopics.filter((topic) => {
        if (primaryTopic) {
            return topic.id !== primaryTopic.id
        }
        return true
    })

    if (secondaryTopics.length > 6) {
        secondaryTopicsList = shuffleArray(secondaryTopicsList)
    }
    return (
        <StyledTopicListContainer className="topic-list hide-print">
            <StyledTopicNav>
                {primaryTopic && (
                    <>
                        <StyledText>Tags:</StyledText>
                        <PNTag topic={primaryTopic} />
                    </>
                )}

                {secondaryTopicsList.map((topic, i) => {
                    return (
                        <div key={topic.id}>
                            <PNTag key={topic.id} topic={topic} />
                        </div>
                    )
                })}
            </StyledTopicNav>
        </StyledTopicListContainer>
    )
}
PNTopicList.displayName = 'TopicList'

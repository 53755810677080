import React, {
    RefObject,
    useContext,
    useState,
    useEffect,
    useRef,
} from 'react'
import {
    RenderTargetContext,
    useFeature,
    AllEvents,
    DataLoaderGlobalParams,
    DataLayerEventName,
} from '@news-mono/web-common'
import {
    breakpoints,
    useWindowWidth,
    PerthNowSection,
    RecaptchaProvider,
} from '@news-mono/component-library'
import { FeatureState } from '@etrigan/feature-toggles-client'
import {
    StyledHamburgerButton,
    StyledHeaderColumn,
    StyledHeaderColumnAd,
    StyledHeaderGrid,
    StyledHeaderRow,
    StyledHeaderShadowWrap,
    StyledLogoWrapHeading,
    StyledLogoWrapLink,
    StyledPerthNowLogo,
    StyledPNHeader,
    StyledPNHeaderLoginWrapperDesktop,
    StyledPNHeaderLoginWrapperMobile,
    StyledPNHeaderWrapper,
    StyledTheWestLogo,
    StyledTheWestLink,
    StyledTWLogoWrapHeading,
    StyledHeaderBorderWrap,
    StyledScroll,
} from './PNHeaderNGN.styled'
import { FeatureToggle } from '../../../feature-toggling/Toggle/Toggle'
import {
    PNAuthLoginActionLinksNGN,
    PNAuthLoginActionLinksNGNOld,
} from '../PNHeaderLoginNGN/PNAuthLoginNGN'
import { PNNavLinkNGN, PNNavigation } from '../PNNavigation/PNNavigation'
import { HamburgerMenu } from '../PNNavigation/HamburgerMenu'
import { IconHamburgerNGN } from '../../../icons/IconHamburgerNGN/IconHamburgerNGN'
import { IconCrossNGN } from '../../../icons/IconCrossNGN/IconCrossNGN'

export interface PNHeaderNGNProps {
    className?: string
    isHome?: boolean
    toggleMobileNav: () => void
    mobileNavExpanded?: boolean
    leaderboardAd?: JSX.Element
    headerRowRef?: RefObject<HTMLDivElement>
    ngnRedesignLeaderboardAdRef?: RefObject<HTMLDivElement>
    ngnRedesignHeaderLogoRowRef?: RefObject<HTMLDivElement>
    onEvent: (event: AllEvents) => void
    navLinks: PNNavLinkNGN[]
    stickyNav: PNNavLinkNGN[]
    section: PerthNowSection
    featureState: FeatureState
    subscribeLink: string
    findMyPaperLink: string
    services: DataLoaderGlobalParams
    isSnapshot?: boolean // This flag is used to determine to skip rendering of navigation and login links
}

const theWestLogoUrl =
    'https://www.thewest.com.au/?utm_medium=referral&utm_campaign=perthnow&utm_content=header#logo'

export const PNHeaderNGN: React.FC<PNHeaderNGNProps> = ({
    className,
    isHome = false,
    toggleMobileNav,
    mobileNavExpanded,
    leaderboardAd,
    headerRowRef,
    ngnRedesignLeaderboardAdRef,
    ngnRedesignHeaderLogoRowRef,
    onEvent,
    navLinks,
    stickyNav,
    section,
    featureState,
    subscribeLink,
    findMyPaperLink,
    services,
    isSnapshot = false,
}) => {
    const windowWidth = useWindowWidth() ?? 0
    const { renderTarget } = useContext(RenderTargetContext)
    const newAuth = useFeature('perthnow-new-auth-tenant')
    const renderTargetValid = renderTarget !== 'app'

    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false)

    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true
        const isBreakpointWrap = windowWidth >= breakpoints.lg
        if (isMounted.current) {
            setShowHamburgerMenu(!isBreakpointWrap)
        }
        return () => {
            isMounted.current = false
        }
    }, [windowWidth])

    // The west logo is only shown on the home page and when the mobile nav is not expanded
    const showWestLogo = renderTargetValid && !mobileNavExpanded

    const handleOnClick = () => {
        onEvent({
            type: DataLayerEventName.navClicked,
            originator: `HeaderLogo`,
            payload: {
                navName: 'Default.PerthNow.Logo.NGNLaunch',
                navLocation: 'Header',
                navText: 'PerthNow',
                navPath: '/',
            },
        })
    }

    const renderPNMainNavigationNGN = () => (
        <PNNavigation
            navLinks={navLinks}
            stickyNav={stickyNav}
            onEvent={onEvent}
            currentSection={section}
            subscribeLink={subscribeLink}
            findMyPaperLink={findMyPaperLink}
            featureState={featureState}
            services={services}
        />
    )

    const renderPNHeaderLoginWrapperDesktop = () => (
        <StyledPNHeaderLoginWrapperDesktop>
            {renderTarget === 'web' &&
                (newAuth ? (
                    <PNAuthLoginActionLinksNGN onEvent={onEvent} />
                ) : (
                    <PNAuthLoginActionLinksNGNOld onEvent={onEvent} />
                ))}
        </StyledPNHeaderLoginWrapperDesktop>
    )

    return (
        <RecaptchaProvider siteKey={services.config?.recaptureSiteKey ?? ''}>
            <StyledPNHeader className={className} role="banner">
                {!mobileNavExpanded && (
                    <StyledHeaderColumnAd ref={ngnRedesignLeaderboardAdRef}>
                        {leaderboardAd}
                    </StyledHeaderColumnAd>
                )}
                <StyledHeaderShadowWrap ref={headerRowRef}>
                    {showWestLogo && (
                        <FeatureToggle
                            feature="the-west-logo"
                            on={() => (
                                <StyledHeaderRow>
                                    <StyledTWLogoWrapHeading>
                                        <StyledTheWestLink
                                            to={theWestLogoUrl}
                                            target="_blank"
                                            rel="noopener"
                                            aria-label="The West Australian"
                                        >
                                            <StyledTheWestLogo />
                                        </StyledTheWestLink>
                                    </StyledTWLogoWrapHeading>
                                </StyledHeaderRow>
                            )}
                        />
                    )}
                </StyledHeaderShadowWrap>

                <StyledHeaderBorderWrap
                    // mobileNavExpanded flag is used to determine the padding-top value to stop the header content from jumping if the west logo is toggled
                    mobileNavExpanded={!!mobileNavExpanded}
                >
                    <StyledHeaderGrid isBreakpointWrap={!showHamburgerMenu}>
                        <StyledHeaderColumn
                            isBreakpointWrap={!showHamburgerMenu}
                            tabIndex={0}
                            ref={ngnRedesignHeaderLogoRowRef}
                        >
                            {isHome ? (
                                <StyledLogoWrapHeading
                                    id="header-logo"
                                    aria-label="Perth Now Home"
                                    tabIndex={-1}
                                >
                                    <StyledPerthNowLogo isHeader />
                                </StyledLogoWrapHeading>
                            ) : (
                                <StyledLogoWrapLink
                                    tabIndex={-1}
                                    to="/"
                                    id="header-logo"
                                    aria-label="Perth Now Home"
                                >
                                    <StyledPerthNowLogo isHeader />
                                </StyledLogoWrapLink>
                            )}
                            {showHamburgerMenu && (
                                <StyledPNHeaderWrapper>
                                    {!mobileNavExpanded && (
                                        <StyledPNHeaderLoginWrapperMobile>
                                            <PNAuthLoginActionLinksNGN
                                                onEvent={onEvent}
                                            />
                                        </StyledPNHeaderLoginWrapperMobile>
                                    )}
                                    <StyledHamburgerButton
                                        onClick={toggleMobileNav}
                                    >
                                        {mobileNavExpanded ? (
                                            <IconCrossNGN />
                                        ) : (
                                            <IconHamburgerNGN />
                                        )}
                                    </StyledHamburgerButton>
                                </StyledPNHeaderWrapper>
                            )}
                        </StyledHeaderColumn>
                        {!isSnapshot &&
                            (showHamburgerMenu ? (
                                <StyledScroll>
                                    {renderPNMainNavigationNGN()}
                                </StyledScroll>
                            ) : (
                                <>
                                    {renderPNMainNavigationNGN()}
                                    {renderPNHeaderLoginWrapperDesktop()}
                                </>
                            ))}
                    </StyledHeaderGrid>
                </StyledHeaderBorderWrap>
                {showHamburgerMenu && (
                    <HamburgerMenu
                        navLinks={navLinks}
                        isOpen={!!mobileNavExpanded}
                        toggleMenu={toggleMobileNav!}
                        currentSection={section}
                        onEvent={onEvent}
                    />
                )}
            </StyledPNHeader>
        </RecaptchaProvider>
    )
}

PNHeaderNGN.displayName = 'PNHeaderNGN'

import React from 'react'

export const IconCopyLinkNGN = () => (
    <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_11554_6086)">
            <path
                d="M3.33203 10.9377H2.66536C2.31174 10.9377 1.9726 10.7972 1.72256 10.5471C1.47251 10.2971 1.33203 9.95795 1.33203 9.60433V3.60433C1.33203 3.25071 1.47251 2.91157 1.72256 2.66152C1.9726 2.41147 2.31174 2.271 2.66536 2.271H8.66536C9.01899 2.271 9.35813 2.41147 9.60817 2.66152C9.85822 2.91157 9.9987 3.25071 9.9987 3.60433V4.271M7.33203 6.93766H13.332C14.0684 6.93766 14.6654 7.53462 14.6654 8.271V14.271C14.6654 15.0074 14.0684 15.6043 13.332 15.6043H7.33203C6.59565 15.6043 5.9987 15.0074 5.9987 14.271V8.271C5.9987 7.53462 6.59565 6.93766 7.33203 6.93766Z"
                stroke="#DD1466"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_11554_6086">
                <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.937744)"
                />
            </clipPath>
        </defs>
    </svg>
)
IconCopyLinkNGN.displayName = 'IconCopyLinkNGN'

import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { PNHades, PNHadesProps } from './PNHades'
import {
    StyledHadesHomepageWrapper,
    StyledHadesArticleWrapper,
} from './PNHades.styled'

export const PNHadesRegistration = createRegisterableComposition<
    'main' | 'sidebar'
>()(
    'pn-hades',
    (
        contentAreas,
        props: Omit<PNHadesProps, 'mainContent' | 'sidebarContent' | 'onEvent'>,
        services,
    ) => {
        const StyledHadesWrapper =
            props.layout === 'articlePage'
                ? StyledHadesArticleWrapper
                : StyledHadesHomepageWrapper
        return (
            <StyledHadesWrapper>
                <PNHades
                    {...props}
                    mainContent={contentAreas.main}
                    sidebarContent={contentAreas.sidebar}
                    onEvent={services.onEvent}
                />
            </StyledHadesWrapper>
        )
    },
)

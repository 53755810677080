import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../mixins'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'

export const StyledTopicListContainer = styled('div')(() => [
    {
        alignItems: 'center',
        display: 'flex',
    },
])

export const StyledTopicNav = styled('nav')({
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
})

export const StyledText = styled('span')(({ theme }) => ({
    ...perthNowNGNTextMixin.label['M'].regular,
    color: colorsPN.text.secondary,
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: calcRem(metricsPN.spacing['2XS']),
}))

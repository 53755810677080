import { AllEvents, DataLayerEventName } from '@news-mono/web-common'
import * as React from 'react'
import { useRef } from 'react'
import { IconAlertCircleTN } from '../../../../icons'
import { useNewsletterFormInput } from '../../helpers/newsletterSignUp'
import {
    StyledButton,
    StyledNewsletterBannerContainer,
    StyledDigitalEditionHeading,
    StyledErrorIcon,
    StyledErrorMessage,
    StyledFloatingLabel,
    StyledForm,
    StyledHeaderContainer,
    StyledInfoContainer,
    StyledInput,
    StyledInputContainer,
    StyledPNHeading,
    StyledPromoText,
    StyledTextContainer,
    StyledLegalLink,
    StyledLegalInfo,
} from './PNNewsletterBannerNGN.styled'

export interface PNNewsletterBannerNGNProps {
    onEvent: (event: AllEvents) => void
    marginTop?: number
    marginBottom?: number
}

export const PNNewsletterBannerNGN: React.FC<PNNewsletterBannerNGNProps> = ({
    onEvent,
}) => {
    const termsLink = 'https://thewest.com.au/terms'
    const newsletterLink = '/manage-email-preferences'
    const privacyLink =
        'https://www.sevenwestmedia.com.au/privacy-policies/privacy'
    const contactLink = '/contact-us'

    const emailAddressInputRef = useRef<HTMLInputElement>(null)

    const {
        handleSubmit,
        handleOnChange,
        handleFocus,
        handleOnClick,
        handleFormReset,
        email,
        error,
        message,
        isSubscribed,
    } = useNewsletterFormInput(onEvent, 'sidebar-homepage')

    const signUpState = (
        <StyledInfoContainer>
            <StyledTextContainer>
                <StyledHeaderContainer>
                    <StyledPNHeading>
                        News&nbsp;
                        <StyledDigitalEditionHeading>
                            to your inbox
                        </StyledDigitalEditionHeading>
                    </StyledPNHeading>
                </StyledHeaderContainer>
                <StyledPromoText>
                    Enter your email for the latest news and updates.
                </StyledPromoText>
            </StyledTextContainer>
            <StyledTextContainer>
                <StyledForm
                    method="POST"
                    onSubmit={handleSubmit}
                    aria-label="Newsletter Signup Form"
                    noValidate
                >
                    <StyledInputContainer>
                        <StyledInput
                            name="email"
                            id="newsletterSignup-email"
                            value={email}
                            autoComplete="off"
                            type="email"
                            data-1p-ignore
                            data-bwignore
                            data-lpignore="true"
                            data-form-type="other"
                            required
                            placeholder=""
                            ref={emailAddressInputRef}
                            onFocus={handleFocus}
                            onChange={handleOnChange}
                            hasError={!!error}
                            aria-label="Email Input"
                        />
                        <StyledFloatingLabel htmlFor="newsletterSignup-email">
                            Enter your email
                        </StyledFloatingLabel>
                        {error && (
                            <>
                                <StyledErrorIcon>
                                    <IconAlertCircleTN />
                                </StyledErrorIcon>
                                <StyledErrorMessage>
                                    {message.current}
                                </StyledErrorMessage>
                            </>
                        )}
                    </StyledInputContainer>
                    <StyledButton
                        aria-label="Sign Up Button"
                        type="submit"
                        onClick={() => {
                            if (
                                emailAddressInputRef.current?.validity
                                    .typeMismatch
                            ) {
                                handleOnClick()
                            }
                        }}
                    >
                        Get the newsletter
                    </StyledButton>
                </StyledForm>
                <StyledLegalInfo>
                    By providing us with your details, you agree to our{' '}
                    <StyledLegalLink
                        to={termsLink}
                        rel="noopener"
                        onClick={() => {
                            if (onEvent) {
                                onEvent({
                                    type: DataLayerEventName.linkClicked,
                                    originator: 'PNNewsletterBannerNGN',
                                    payload: {
                                        text: 'Newsletter Banner',
                                        linkType: 'Terms of use',
                                        opensInNewWindow: true,
                                        url: termsLink,
                                    },
                                })
                            }
                        }}
                        aria-label="Manage Email Preferences"
                    >
                        Terms of Use
                    </StyledLegalLink>{' '}
                    and{' '}
                    <StyledLegalLink
                        to={privacyLink}
                        rel="noopener"
                        onClick={() => {
                            if (onEvent) {
                                onEvent({
                                    type: DataLayerEventName.linkClicked,
                                    originator: 'PNNewsletterBannerNGN',
                                    payload: {
                                        text: 'Newsletter Banner',
                                        linkType: 'Privacy statement',
                                        opensInNewWindow: true,
                                        url: privacyLink,
                                    },
                                })
                            }
                        }}
                        aria-label="Manage Email Preferences"
                    >
                        Privacy Statement
                    </StyledLegalLink>
                    .
                </StyledLegalInfo>
            </StyledTextContainer>
        </StyledInfoContainer>
    )

    const postSignUpState = (
        <StyledInfoContainer submitted>
            <StyledTextContainer>
                <StyledHeaderContainer>
                    <StyledPNHeading>
                        <StyledDigitalEditionHeading>
                            Thanks! You're all signed up.
                        </StyledDigitalEditionHeading>
                    </StyledPNHeading>
                </StyledHeaderContainer>
                <StyledPromoText>
                    The PerthNow newsletter will be in your inbox soon!
                </StyledPromoText>
                <StyledLegalInfo>
                    <StyledLegalLink
                        to={newsletterLink}
                        rel="noopener"
                        onClick={() => {
                            if (onEvent) {
                                onEvent({
                                    type: DataLayerEventName.linkClicked,
                                    originator: 'PNNewsletterBannerNGN',
                                    payload: {
                                        text: 'Newsletter Banner',
                                        linkType: 'More newsletters',
                                        opensInNewWindow: false,
                                        url: newsletterLink,
                                    },
                                })
                            }
                        }}
                        aria-label="Manage Email Preferences"
                    >
                        More newsletters
                    </StyledLegalLink>
                </StyledLegalInfo>
            </StyledTextContainer>
        </StyledInfoContainer>
    )

    const errorState = (
        <StyledInfoContainer submitted>
            <StyledTextContainer>
                <StyledHeaderContainer>
                    <StyledPNHeading>
                        <StyledDigitalEditionHeading>
                            Something went wrong!
                        </StyledDigitalEditionHeading>
                    </StyledPNHeading>
                </StyledHeaderContainer>
                <StyledPromoText>
                    Please try again in a few moments, or{' '}
                    <StyledLegalLink
                        to={contactLink}
                        rel="noopener"
                        onClick={() => {
                            if (onEvent) {
                                onEvent({
                                    type: DataLayerEventName.linkClicked,
                                    originator: 'PNNewsletterBannerNGN',
                                    payload: {
                                        text: 'Newsletter Banner',
                                        linkType: 'Contact us',
                                        opensInNewWindow: false,
                                        url: contactLink,
                                    },
                                })
                            }
                        }}
                        aria-label="Contact us"
                    >
                        contact us{' '}
                    </StyledLegalLink>
                    if the issue persists.
                </StyledPromoText>
            </StyledTextContainer>
            <StyledTextContainer>
                <StyledButton
                    aria-label="Try again"
                    type="button"
                    onClick={handleFormReset}
                >
                    Try again
                </StyledButton>
            </StyledTextContainer>
        </StyledInfoContainer>
    )

    let body = signUpState

    if (isSubscribed === 'submitted' && !error) {
        body = postSignUpState
    }

    if (error && !message.current) {
        body = errorState
    }

    return (
        <StyledNewsletterBannerContainer>
            {body}
        </StyledNewsletterBannerContainer>
    )
}

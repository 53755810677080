import React from 'react'
import { createRegisterableComposition } from '@news-mono/web-common'
import { BaseCompositionProps } from '../types'
import { PNTriton, PNTritonHeader } from './PNTriton'
import { StyledTritonWrapper } from './PNTriton.styled'

export interface PNTritonRouteProps extends BaseCompositionProps {
    heading?: PNTritonHeader
    isTopSection?: boolean
}

export const PNTritonRegistration = createRegisterableComposition<
    'left' | 'main' | 'right'
>()('pn-triton', (contentAreas, props: PNTritonRouteProps, services) => (
    <StyledTritonWrapper isTopSection={props.isTopSection}>
        <PNTriton
            {...props}
            leftSidebarContent={contentAreas.left}
            mainContent={contentAreas.main}
            rightSidebarContent={contentAreas.right}
            onEvent={services.onEvent}
        />
    </StyledTritonWrapper>
))

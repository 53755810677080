import { WebLink } from '@news-mono/web-common'
import { breakpoint, calcRem } from '../../../../__styling/style-functions'
import { IconChevronDownTN } from '../../../../icons/IconChevronDownTN/IconChevronDownTN'
import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../mixins'

export const PNBreadcrumbContainer = styled.div(({ theme }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: 0,
        marginBottom: metricsPN.spacing['L'],
    }
})

interface TopicChevronWrapperProps {
    showPadding?: boolean
}
export const TopicChevronWrapper = styled.div<TopicChevronWrapperProps>(
    ({ showPadding }) => {
        return {
            display: 'flex',
            alignItems: 'center',
            gap: calcRem(metricsPN.spacing['4XS']),
            padding: showPadding
                ? 0
                : calcRem(
                      0,
                      metricsPN.spacing['S'],
                      metricsPN.spacing['2XS'],
                      0,
                  ),

            [breakpoint('sm')]: {
                padding: 0,
            },
            transition: 'padding 0.25s',
            flexWrap: 'wrap',
        }
    },
)

export const TopicContainer = styled.nav(() => {
    return {
        display: 'flex',
        alignItems: 'center',
        gap: calcRem(metricsPN.spacing['2XS']),
        flexWrap: 'wrap',
    }
})

export const ChevronContainer = styled.button(() => {
    return {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: 0,

        // Focus style
        '&:focus-visible': {
            outline: `2px solid ${colorsPN.stroke.brand.strong}`,
            borderRadius: metricsPN.radius['S'],
        },
    }
})

export const StyledChevron = styled(IconChevronDownTN)<{ isOpen: boolean }>(
    ({ isOpen }) => {
        return {
            width: calcRem(24),
            height: calcRem(24),
            display: 'flex',
            color: colorsPN.text.brand,

            rotate: `${isOpen ? -180 : 0}deg`,

            transition: 'rotate 0.25s',
        }
    },
)

export const StyledTopic = styled(WebLink)<{ isOpen: boolean }>(
    ({ isOpen = false }) => {
        return [
            {
                color: isOpen ? colorsPN.text.brand : colorsPN.text.secondary,
                textDecoration: 'none',
                ...perthNowNGNTextMixin.label['M'].medium,

                ':hover': {
                    textDecoration: 'underline',
                },

                // Focus style
                '&:focus-visible': {
                    outline: `2px solid ${colorsPN.stroke.brand.strong}`,
                    outlineOffset: calcRem(metricsPN.spacing['5XS']),
                    borderRadius: metricsPN.radius['S'],
                },
            },
        ]
    },
)

export const TopicDivider = styled.div(() => {
    return {
        width: calcRem(1),
        height: calcRem(16),
        backgroundColor: colorsPN.stroke.strong,
    }
})

export const SecondaryTopicsWrapper = styled.div<{ isOpen: boolean }>(
    ({ isOpen }) => {
        return {
            display: 'grid',
            gridTemplateRows: `${isOpen ? 1 : 0}fr`,
            marginTop: isOpen ? metricsPN.spacing['4XS'] : 0,

            transition: 'grid-template-rows 0.25s, margin 0.25s',
        }
    },
)

export const SecondaryTopicsContainer = styled.ol<{ isOpen: boolean }>(
    ({ isOpen }) => {
        return {
            // Allows the container to collapse.
            minHeight: 0,

            display: 'flex',
            paddingLeft: 0,
            margin: 0,
            listStyle: 'none',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: metricsPN.spacing['4XS'],
            overflow: isOpen ? 'visible' : 'hidden',
        }
    },
)

interface TopicItemProps {
    isCurrent?: boolean
}
export const SecondaryTopicsItem = styled.li<TopicItemProps>(
    ({ isCurrent }) => {
        return [
            {
                cursor: 'pointer',
                color: colorsPN.text.primary,
                padding: calcRem(metricsPN.spacing['3XS'], 0),
            },
            isCurrent
                ? {
                      ...perthNowNGNTextMixin.label.M.semibold,
                  }
                : { ...perthNowNGNTextMixin.label.M.regular },
        ]
    },
)

export const StyledLink = styled(WebLink)(({ theme }) => {
    return {
        color: colorsPN.text.primary,
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },

        // Focus style
        '&:focus-visible': {
            outline: `2px solid ${colorsPN.stroke.brand.strong}`,
            borderRadius: metricsPN.radius['S'],
            outlineOffset: '2px',
        },
    }
})

import React, { useState, useEffect } from 'react'
import {
    IconCopyLinkNGN,
    IconCheckNGN,
    useSectionMetaInfo,
} from '@news-mono/component-library'
import { SharingEvent } from '@news-mono/web-common'
import { StyledPNSharingWrapper } from './PNCopyLink.styled'
import { getButtonOnClick } from '../../../../../buttons/Sharing/utils/get-button-on-click'

export interface PNCopyLinkProps {
    onEvent: (event: SharingEvent) => void
    url: string
    text: string
}

const PNCopyLink: React.FC<PNCopyLinkProps> = ({ onEvent, url, text }) => {
    const sectionMeta = useSectionMetaInfo()
    const [copied, setCopied] = useState(false)
    const { FacebookAppId } = sectionMeta

    useEffect(() => {
        let timeoutId: NodeJS.Timeout

        if (copied) {
            // Reset copied state after 1.5 seconds
            timeoutId = setTimeout(() => {
                setCopied(false)
            }, 1500)
        }

        return () => {
            clearTimeout(timeoutId)
        }
    }, [copied])

    const handleClick = () => {
        setCopied(true)
        onEvent({
            type: 'shareButton.click',
            originator: 'Sharing',
            payload: {
                shareType: 'clipboard',
            },
        })
        getButtonOnClick('clipboard', url, text, FacebookAppId)()
    }

    return (
        <StyledPNSharingWrapper
            aria-label={'Copy link to clipboard'}
            onClick={handleClick}
        >
            {copied ? `Copied!` : `Copy link`}
            {copied ? <IconCheckNGN /> : <IconCopyLinkNGN />}
        </StyledPNSharingWrapper>
    )
}

PNCopyLink.displayName = 'PNCopyLink'
export default PNCopyLink

import { PerthNowSection } from '@news-mono/component-library'
import {
    Features,
    GetRouteAdTargeting,
    PageType,
    Store,
} from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../App.routing'
import { evergreenTopicSection } from './get-evergreen-topic-segment'
import { topStoriesSection } from './get-top-stories-segment'
import { dailySection } from './get-daily-section-segment'
import { PerthNowSections } from '@west-australian-newspapers/publication-types'
import { navLinks } from './home-nav-links'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'

export type PerthNowSectionKeys = keyof Omit<PerthNowSections, 'default'>
type NavLinks = keyof typeof navLinks['original'] | keyof typeof navLinks['ngn']

export interface TopicSectionParams {
    /** section topic on homepage. */
    topic: string
    /** Url to navigate to from the "See all >" link. */
    headingUrl?: string
    /** Defaults to the topic. */
    curation?: string
    /** Specify the trending stories in 'Trending now' section */
    automatedCuration?: string
    /** Defaults to the topic. */
    sectionTitle?: string
    /** Navigation Links to display below heading */
    navLinkSection?: NavLinks
    /** Determines whether to use the original or new taxonomy */
    taxonomyVersion: 'original' | 'ngn' // TODO: Remove when taxonomy is changed over permanently to new version
    /** Additional component under the sidebar. e.g. promotional card */
    afterSidebarComponent?: CheckedComponentInformation[]
}

export const homepageLayoutNGN = (
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
): PageType<PerthNowSection> => {
    const pageMetaDescription =
        "PerthNow, Western Australia's latest news and stories including business, sport, entertainment, international and more."

    const useNewTaxonomy = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        'perthnow-ngn-new-taxonomy',
    )

    const taxonomyVersion = useNewTaxonomy ? 'ngn' : 'original'

    return {
        kind: 'page',
        heading: 'Home',
        pageType: 'homepage',
        hideHeading: true,
        refreshOnInactive: true,
        noMetaTitleTemplate: true,
        pageMeta: {
            title: 'PerthNow | Breaking News from Perth and Western Australia',
            description: pageMetaDescription,
            meta: [
                {
                    property: 'og:description',
                    content: pageMetaDescription,
                },
                {
                    property: 'twitter:description',
                    content: pageMetaDescription,
                },
            ],
        },
        adTargeting: getAdTargeting('home', 'default', 'home'),
        section: 'default',
        compositions: [
            ...topStoriesSection(),
            getAdComponent('one'),
            ...dailySection({
                topic: useNewTaxonomy ? 'wa' : 'news/wa',
                curation: 'wa-news',
                automatedCuration: 'wa-news',
                sectionTitle: 'WA News',
                headingUrl: useNewTaxonomy ? '/wa' : '/news/wa',
                navLinkSection: 'wa news',
                taxonomyVersion: taxonomyVersion,
                // Digital Edition Banner
                afterSidebarComponent: [
                    layout.component({
                        type: 'pn-digital-edition-promo-ngn',
                        props: {},
                    }),
                ],
            }),
            getAdComponent('two'),
            ...evergreenTopicSection({
                topic: 'entertainment',
                sectionTitle: 'Entertainment',
                headingUrl: '/entertainment',
                navLinkSection: 'entertainment',
                taxonomyVersion: taxonomyVersion,
                // Curated Promo Card
                afterSidebarComponent: [
                    layout.component({
                        type: 'pn-theme-card',
                        props: {
                            theme: 'default',
                            dataDefinitionArgs: {
                                type: 'active-promotion',
                                promotionSlotId: 'homepage-position-2',
                                cardNumber: 2,
                            },
                        },
                    }),
                ],
            }),
            getAdComponent('three'),
            ...dailySection({
                topic: 'news',
                sectionTitle: 'News',
                headingUrl: '/news',
                navLinkSection: 'news',
                taxonomyVersion: taxonomyVersion,
                // Newsletter Signup Banner
                afterSidebarComponent: [
                    layout.component({
                        type: 'pn-newsletter-banner-ngn',
                        props: {},
                    }),
                ],
            }),
            getAdComponent('four'),
            ...evergreenTopicSection({
                topic: useNewTaxonomy ? 'food' : 'lifestyle/food',
                curation: 'bites-and-booze',
                automatedCuration: 'food',
                sectionTitle: 'Food',
                headingUrl: useNewTaxonomy ? '/food' : '/lifestyle/food',
                navLinkSection: 'food',
                taxonomyVersion: taxonomyVersion,
                // Curated Promo Card
                afterSidebarComponent: [
                    layout.component({
                        type: 'pn-theme-card',
                        props: {
                            theme: 'default',
                            dataDefinitionArgs: {
                                type: 'active-promotion',
                                promotionSlotId: 'homepage-position-3',
                                cardNumber: 3,
                            },
                        },
                    }),
                ],
            }),
            getAdComponent('five'),
            ...evergreenTopicSection({
                topic: 'lifestyle',
                sectionTitle: 'Lifestyle',
                headingUrl: '/lifestyle',
                navLinkSection: 'lifestyle',
                taxonomyVersion: taxonomyVersion,
                // Curated Promo Card
                afterSidebarComponent: [
                    layout.component({
                        type: 'pn-theme-card',
                        props: {
                            theme: 'default',
                            dataDefinitionArgs: {
                                type: 'active-promotion',
                                promotionSlotId: 'homepage-position-4',
                                cardNumber: 4,
                            },
                        },
                    }),
                ],
            }),
            getAdComponent('six'),
            ...dailySection({
                topic: 'sport',
                sectionTitle: 'Sport',
                headingUrl: '/sport',
                navLinkSection: 'sport',
                taxonomyVersion: taxonomyVersion,
                // Themed Promo Card / Play of the day
                afterSidebarComponent: [
                    layout.component({
                        type: 'pn-theme-card',
                        props: {
                            theme: 'streamer-screamer',
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: `play-of-the-day`,
                                offset: 0,
                                pageSize: 1,
                            },
                        },
                    }),
                ],
            }),
            getAdComponent('seven'),
            ...evergreenTopicSection({
                topic: 'shop-now',
                curation: 'shop-now',
                automatedCuration: 'shopnow',
                sectionTitle: 'ShopNow',
                headingUrl: '/shop-now',
                navLinkSection: 'shop-now',
                taxonomyVersion: taxonomyVersion,
                // Curated Promo Card
                afterSidebarComponent: [
                    layout.component({
                        type: 'pn-theme-card',
                        props: {
                            theme: 'default',
                            dataDefinitionArgs: {
                                type: 'active-promotion',
                                // TODO: Create and update to position 5 when taxonomy change occurs.
                                promotionSlotId: 'homepage-position-2',
                                cardNumber: 5,
                            },
                        },
                    }),
                ],
            }),
            getAdComponent('eight'),
            // Brand Partners
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'eris',
                            props: {
                                sectionHeader: {
                                    heading: 'Brand partners',
                                    popover: true,
                                },
                                popover: true,
                                expectedCards: 6,
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'brand-partners',
                                    offset: 0,
                                    pageSize: 6,
                                },
                            },
                        }),
                    ],
                },
            }),
            // Taboola Feed
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-home-thumbnails',
                                locationType: 'home',
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}

function mainContentSection(
    components: Array<CheckedComponentInformation>,
    feature?: Features,
    hasBackgroundFill?: boolean,
) {
    return layout.composition({
        type: 'thor',
        feature: feature,
        props: {
            hasBackgroundFill: false,
            containerWidth: '100%',
            horizontalGutters: false,
            verticalGutters: false,
        },
        contentAreas: {
            main: [
                layout.nestedComposition({
                    type: 'thor',
                    props: {
                        hasBackgroundFill: hasBackgroundFill,
                        containerWidth: '100%',
                        verticalGutters: false,
                        horizontalGutters: false,
                    },
                    contentAreas: {
                        main: components,
                    },
                }),
            ],
        },
    })
}

function getAdComponent(adSlot: string) {
    return mainContentSection([
        layout.component({
            type: 'ad-unit',
            props: {
                noticePosition: 'below-center',
                hiddenUntilLoaded: true,
                slot: {
                    id: `billboard-leaderboard-${adSlot}`,
                    size: 'billboardLeaderboardAbove1280leaderboardMrecAbove768MrecBelow',
                },
                adType: 'inline',
            },
        }),
    ])
}

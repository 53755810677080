import React, { useRef } from 'react'
import {
    DataLayerEventName,
    NavEvent,
    AllEventTypes,
    AllEvents,
} from '@news-mono/web-common'
import { sendStoryLink } from '@news-mono/component-library'

import {
    StyledSubscribeLinksWrapper,
    StyledTopicHeader,
    StyledSubscribeSpan,
    StyledForm,
    StyledInputContainer,
    StyledFloatingLabel,
    StyledInput,
    StyledErrorMessage,
    StyledErrorIcon,
    StyledButton,
    StyledLink,
    StyledSubscribeSuccessSpan,
    StyledDivWrapper,
} from './SecondaryMenuContainer.styled'
import { IconAlertCircleTN } from '../../../../../icons/IconAlertCircleTN/IconAlertCircleTN'
import { NewsletterFormInputHook } from '../../../helpers/newsletterSignUp'
export interface SubscriberLinksProps {
    subscribeLink: string
    findMyPaperLink: string
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    onEvent: (event: AllEvents) => void
    formHook: NewsletterFormInputHook
    tabIndex: number
}

export const newsletterSubscribeSource = 'PerthNow Newsletters CTA'

export const SubscriberLinks: React.FC<SubscriberLinksProps> = ({
    subscribeLink,
    findMyPaperLink,
    isOpen,
    onEvent,
    setIsOpen,
    formHook,
    tabIndex,
}) => {
    const linkTabIndex = isOpen ? tabIndex : -1
    const emailAddressInputRef = useRef<HTMLInputElement>(null)

    const {
        handleSubmit,
        handleOnChange,
        handleFocus,
        handleOnClick,
        email,
        error,
        message,
        isSubscribed,
    } = formHook

    const fireEvent = (
        eventType: AllEventTypes,
        originator: string,
        payload: Record<string, unknown>,
    ) => {
        onEvent({
            type: eventType,
            originator,
            payload,
        } as NavEvent)
    }

    // External links / operations
    const onLinkClicked = (
        link: string,
        linkText: string,
        newWindow?: boolean,
    ) => {
        setIsOpen(false)
        fireEvent(DataLayerEventName.linkClicked, 'PNSubscribeLinks', {
            text: linkText,
            linkType: 'Default.PerthNow.MegaMenu',
            opensInNewWindow: newWindow,
            url: link,
        })
    }

    // Links to a page within PerthNow
    const onNavClicked = (navLink: string, navText: string) => {
        setIsOpen(false)
        fireEvent(DataLayerEventName.navClicked, 'PNSubscribeLinks', {
            navName: 'Default.PerthNow.MegaMenu',
            navLocation: 'Header',
            navText,
            navLink,
        })
    }

    return (
        <StyledSubscribeLinksWrapper aria-label="Subscription Section">
            <StyledDivWrapper>
                {isSubscribed === 'submitted' ? (
                    <>
                        <StyledTopicHeader margin={[0, 0, 12, 0]}>
                            Thanks for signing up!
                        </StyledTopicHeader>
                        <StyledSubscribeSuccessSpan>
                            Check your inbox for the latest in Perth Now.
                        </StyledSubscribeSuccessSpan>
                    </>
                ) : (
                    <>
                        <StyledTopicHeader margin={[0, 0, 12, 0]}>
                            Stay in the loop with all things Perth
                        </StyledTopicHeader>
                        <StyledSubscribeSpan>
                            Subscribe now for the latest news and updates.
                        </StyledSubscribeSpan>
                        <StyledForm
                            method="POST"
                            onSubmit={handleSubmit}
                            aria-label="Newsletter Signup Form"
                            noValidate
                        >
                            <StyledInputContainer>
                                <StyledInput
                                    tabIndex={linkTabIndex}
                                    name="email"
                                    id="newsletterSignup-email"
                                    value={email}
                                    autoComplete="off"
                                    type="email"
                                    data-1p-ignore
                                    data-bwignore
                                    data-lpignore="true"
                                    data-form-type="other"
                                    required
                                    placeholder=""
                                    ref={emailAddressInputRef}
                                    onFocus={handleFocus}
                                    onChange={handleOnChange}
                                    hasError={!!error}
                                    aria-label="Email Input"
                                />
                                <StyledFloatingLabel htmlFor="newsletterSignup-email">
                                    Enter your email
                                </StyledFloatingLabel>
                                {error && (
                                    <>
                                        <StyledErrorIcon>
                                            <IconAlertCircleTN />
                                        </StyledErrorIcon>
                                        <StyledErrorMessage>
                                            {message.current}
                                        </StyledErrorMessage>
                                    </>
                                )}
                            </StyledInputContainer>
                            <StyledButton
                                aria-label="Sign Up Button"
                                type="submit"
                                tabIndex={linkTabIndex}
                                onClick={() => {
                                    if (
                                        emailAddressInputRef.current?.validity
                                            .typeMismatch
                                    ) {
                                        handleOnClick()
                                    }
                                }}
                            >
                                Sign Up
                            </StyledButton>
                        </StyledForm>
                    </>
                )}

                <StyledLink
                    to={subscribeLink}
                    rel="noopener"
                    onClick={() =>
                        onLinkClicked(subscribeLink, 'See all newsletters')
                    }
                    tabIndex={linkTabIndex}
                    aria-label="Manage Email Preferences"
                >
                    See all newsletters
                </StyledLink>
            </StyledDivWrapper>
            <StyledDivWrapper>
                <StyledTopicHeader margin={[0, 0, 12, 0]}>
                    Have you got a story to share?
                </StyledTopicHeader>
                <StyledLink
                    to={sendStoryLink}
                    onClick={() =>
                        onLinkClicked(sendStoryLink, 'Submit a story', true)
                    }
                    tabIndex={linkTabIndex}
                    aria-label="Submit a Story"
                >
                    Submit a story
                </StyledLink>
            </StyledDivWrapper>
            <StyledDivWrapper>
                <StyledTopicHeader margin={[0, 0, 12, 0]}>
                    Prefer the printed paper?
                </StyledTopicHeader>
                <StyledLink
                    to={findMyPaperLink}
                    rel="noopener"
                    onClick={() =>
                        onNavClicked(findMyPaperLink, 'Find my paper')
                    }
                    tabIndex={linkTabIndex}
                    aria-label="Find My Paper"
                >
                    Find my paper
                </StyledLink>
            </StyledDivWrapper>
        </StyledSubscribeLinksWrapper>
    )
}

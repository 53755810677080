import React from 'react'
import {
    PNBreadcrumbContainer,
    StyledChevron,
    StyledTopic,
    TopicDivider,
    TopicContainer,
    SecondaryTopicsContainer,
    ChevronContainer,
    TopicChevronWrapper,
    SecondaryTopicsItem,
    SecondaryTopicsWrapper,
    StyledLink,
} from './PNBreadcrumb.styled'
import { PNBreadcrumbProps } from './PNBreadcrumb.routing'
import { RoutablePerthNowSection } from '@west-australian-newspapers/publication-types'
import { NavItem } from '@news-mono/web-common'
import { PerthNowNavItem } from '../../../../routing'

export const PNBreadcrumb: React.FC<PNBreadcrumbProps> = ({
    topics,
    perthNowSectionNavItems,
    subTopicNavItems,
}) => {
    const [showSecondaryTopics, setShowSecondaryTopics] = React.useState(false)
    const { primary } = topics

    const primaryTopic = primary.parent ?? primary
    const hasSecondaryTopic = primary.parent !== undefined

    let parentTopic: PerthNowNavItem | undefined
    let subTopic: NavItem | undefined
    let secondaryTopics: NavItem[] = []

    if (primaryTopic.id in subTopicNavItems) {
        parentTopic =
            perthNowSectionNavItems[primaryTopic.id as RoutablePerthNowSection]
        secondaryTopics =
            parentTopic?.subNavLinks?.filter(
                (subNavLink) =>
                    subNavLink.name.toLowerCase().indexOf('all') === -1,
            ) ?? []

        console.log('Sub topics: ', secondaryTopics)
        if (hasSecondaryTopic) {
            subTopic = subTopicNavItems[
                primaryTopic.id as RoutablePerthNowSection
            ].find((item) => item.link === `/${primary.id}`)
        }
    }

    return (
        <PNBreadcrumbContainer>
            <TopicChevronWrapper showPadding={showSecondaryTopics}>
                <TopicContainer aria-label="breadcrumbs">
                    {parentTopic && (
                        <StyledTopic
                            to={parentTopic.link}
                            isOpen={showSecondaryTopics}
                        >
                            {parentTopic.name}
                        </StyledTopic>
                    )}
                    {subTopic && (
                        <>
                            <TopicDivider aria-hidden="true" />
                            <StyledTopic
                                to={`${subTopic.link}`}
                                isOpen={showSecondaryTopics}
                            >
                                {subTopic.name}
                            </StyledTopic>
                        </>
                    )}
                </TopicContainer>
                {secondaryTopics.length > 0 && (
                    <ChevronContainer
                        tabIndex={0}
                        onClick={() =>
                            setShowSecondaryTopics(!showSecondaryTopics)
                        }
                    >
                        <StyledChevron isOpen={showSecondaryTopics} />
                    </ChevronContainer>
                )}
            </TopicChevronWrapper>
            {secondaryTopics && (
                <SecondaryTopicsWrapper isOpen={showSecondaryTopics}>
                    <SecondaryTopicsContainer isOpen={showSecondaryTopics}>
                        {secondaryTopics.map((topic, index) => {
                            const isCurrent = topic.name === subTopic?.name

                            return (
                                <SecondaryTopicsItem
                                    isCurrent={isCurrent}
                                    key={index}
                                >
                                    <StyledLink
                                        to={`${topic.link}`}
                                        aria-current={
                                            isCurrent ? 'location' : undefined
                                        }
                                        tabIndex={showSecondaryTopics ? 0 : -1}
                                    >
                                        {topic.name}
                                    </StyledLink>
                                </SecondaryTopicsItem>
                            )
                        })}
                    </SecondaryTopicsContainer>
                </SecondaryTopicsWrapper>
            )}
        </PNBreadcrumbContainer>
    )
}

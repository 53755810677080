import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { withClass } from '@news-mono/web-common'
import { StyledArticleCard } from '../../nextgen-news/perthnow/cards/PNArticleCard/PNArticleCard.styled'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const StyledHadesHomepageWrapper = styled('section')(() => {
    return {
        marginBottom: calcRem(metricsPN.layout.section.mobile),
        marginTop: calcRem(metricsPN.layout.section.mobile),
        [breakpoint('sm')]: {
            marginBottom: calcRem(metricsPN.layout.section.tablet),
            marginTop: calcRem(metricsPN.layout.section.tablet),
        },

        [breakpoint('lg')]: {
            marginBottom: calcRem(metricsPN.layout.section.desktop),
            marginTop: calcRem(metricsPN.layout.section.desktop),
        },
    }
})

export const StyledHadesArticleWrapper = styled('main')({
    marginTop: calcRem(metricsPN.spacing['L']),
    marginBottom: calcRem(metricsPN.spacing['L']),

    [breakpoint('sm')]: {
        marginTop: calcRem(metricsPN.spacing['XL']),
        marginBottom: calcRem(metricsPN.spacing['L']),
    },

    [breakpoint('lg')]: {
        marginTop: calcRem(metricsPN.spacing['L']),
    },
})

const mainColumnStart = 1
const mainColumnSize = 5
const columnTotal = 7

export const StyledPNHades = styled('div')(() => {
    return {
        margin: '0 auto',
        padding: 0,
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '100%',
        gridGap: calcRem(metricsPN.spacing['XL']),

        '@media print': {
            display: 'block',
        },

        [breakpoint('lg')]: {
            gridTemplateColumns: `repeat(${columnTotal}, 1fr)`,
            gridGap: calcRem(metricsPN.spacing['L']),
        },
    }
})
StyledPNHades.displayName = 'StyledPNHades'

export const StyledMain = styled('div')<{ layout: 'homepage' | 'articlePage' }>(
    ({ layout }) => ({
        display: 'grid',
        gridGap:
            layout === 'homepage' ? calcRem(metricsPN.spacing['XL'] * 2) : 0,
        gridTemplateRows: 'auto 1fr',

        [breakpoint('lg')]: {
            gridColumnStart: mainColumnStart,
            gridColumnEnd: mainColumnStart + mainColumnSize,
        },

        [`& > ${StyledArticleCard}:first-child::after`]: {
            content: `''`,
            backgroundColor: colorsPN.stroke.weak,
            width: '100%',
            height: 1,
            position: 'relative',
            left: 0,
            bottom: calcRem(-metricsPN.spacing['XL']),
            zIndex: zIndex.common.collectionDividers,
            gridColumn: `1 / -1`,
        },
    }),
)
StyledMain.displayName = 'StyledMain'

export const StyledSidebar = withClass('hide-print')(
    styled('div')<{
        layout: 'homepage' | 'articlePage'
    }>(({ layout }) => {
        return {
            display: layout === 'homepage' ? 'block' : 'none',
            transform: 'translateZ(0)',
            paddingTop: layout === 'homepage' ? 0 : calcRem(50), // 48px from breadcrumb + 2 from outstream2x2 ad

            [breakpoint('lg')]: {
                display: 'block',
                boxSizing: 'content-box',
                position: 'relative',
                zIndex: zIndex.perthnow.sidebar,
                gridColumnStart: mainColumnStart + mainColumnSize,
                gridColumnEnd: columnTotal + 1,
            },
        }
    }),
)
StyledSidebar.displayName = 'StyledSidebar'

import {
    metrics,
    PerthNowSection,
    sectionThemeOverrider,
} from '@news-mono/component-library'
import { StaticRoutes } from '@news-mono/web-common'
import { layout } from '../App.routing'

export const horoscopes: StaticRoutes<PerthNowSection> = {
    '/lifestyle/horoscopes': ({ getAdTargeting }) => ({
        kind: 'page',
        heading: 'Horoscopes',
        pageType: 'topic',
        pageMeta: {
            title: 'Horoscopes',
            description: 'Horoscopes news and updates from PerthNow',
        },
        adTargeting: getAdTargeting('horoscopes', 'lifestyle', 'horoscopes'),
        section: 'lifestyle',
        compositions: [
            layout.composition({
                type: 'zeus',
                props: {
                    hasBackgroundFill: false,
                    verticalGutters: true,
                    isVideoStickyEnabled: false,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'horoscope',
                            props: {
                                inlineContentAds: [
                                    {
                                        hiddenUntilLoaded: true,
                                        noticePosition: 'none',
                                        padding: [
                                            metrics.perthnow.margins.sm,
                                            0,
                                            metrics.perthnow.margins.sm,
                                            0,
                                        ],
                                        slot: {
                                            id: 'mobile-mrec-one',
                                            size: 'mobileMrec',
                                        },
                                        adType: 'inline',
                                    },
                                    {
                                        hiddenUntilLoaded: true,
                                        noticePosition: 'none',
                                        padding: [
                                            metrics.perthnow.margins.sm,
                                            0,
                                            metrics.perthnow.margins.sm,
                                            0,
                                        ],
                                        slot: {
                                            id: 'mobile-mrec-two',
                                            size: 'mobileMrec',
                                        },
                                        adType: 'inline',
                                    },
                                    {
                                        hiddenUntilLoaded: true,
                                        noticePosition: 'none',
                                        padding: [
                                            metrics.perthnow.margins.sm,
                                            0,
                                            metrics.perthnow.margins.sm,
                                            0,
                                        ],
                                        slot: {
                                            id: 'mobile-mrec-three',
                                            size: 'mobileMrec',
                                        },
                                        adType: 'inline',
                                    },
                                ],
                                dataDefinitionArgs: {},
                            },
                        }),
                    ],
                    sidebar: [
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [0, 0, 13, 0],
                                slot: {
                                    id: 'sidebar-top',
                                    size: 'desktopMrecHalfPage',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.nestedComposition({
                            type: 'scheme-overrider',
                            props: {
                                override: sectionThemeOverrider(
                                    'perthnow',
                                    'lifestyle',
                                ),
                            },
                            contentAreas: {
                                children: [
                                    layout.component({
                                        type: 'juliet',
                                        props: {
                                            cardType: {
                                                type: 'landscape',
                                                format: 'landscape-common',
                                            },
                                            removeHorizontalGutters: true,
                                            kickerMode: 'hidden',
                                            sectionHeader: {
                                                heading: 'Also in lifestyle',
                                            },
                                            dataDefinitionArgs: {
                                                type: 'listing',
                                                topics: ['lifestyle'],
                                                includeSubTopics: true,
                                                paging: {
                                                    page: 1,
                                                    pageSize: 16,
                                                },
                                            },
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'below-center',
                                padding: [13, 0],
                                slot: {
                                    id: 'sidebar-middle',
                                    size: 'desktopMrec',
                                },
                                adType: 'inline',
                            },
                        }),
                        layout.component({
                            type: 'juliet',
                            props: {
                                cardType: {
                                    type: 'landscape',
                                    format: 'landscape-common',
                                },
                                removeHorizontalGutters: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['lifestyle'],
                                    includeSubTopics: true,
                                    paging: {
                                        page: 2,
                                        pageSize: 16,
                                    },
                                },
                                kickerMode: 'hidden',
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'zeus',
                feature: 'taboola',
                props: { hasBackgroundFill: false },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-article-thumbnails',
                                locationType: 'category',
                            },
                        }),
                    ],
                    sidebar: [],
                },
            }),
        ],
    }),
}

import { PerthNowNavItem } from '@news-mono/component-library'
import { PerthNowSections } from '@west-australian-newspapers/publication-types'
import { StaticNavRouteHints } from 'web-common'

/** These are route hints for items in the static navigation (nav & sections currently), so they can render a skeleton
 *
 * It is separate to the navigation because it's only needed for items which are not also
 * in our staticRoutes
 *
 * Future: When we load a page, we could look at it's section nav and load this info on the fly?
 */

export const staticNavRouteHints: StaticNavRouteHints = {
    '/news': {
        routeKind: 'topic',
        topic: {
            id: 'news',
            title: 'News',
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/news/world': {
        routeKind: 'topic',
        topic: {
            id: 'news/world',
            title: 'World News',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/news/weather': {
        routeKind: 'topic',
        topic: {
            id: 'news/weather',
            title: 'Weather',
            parent: { id: 'news', title: 'News', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/news/gallery': {
        routeKind: 'topic',
        topic: { id: 'news', title: 'News', metadata: {}, seoTitle: '' },
        publicationKind: 'gallery',
    },
    '/technology': {
        routeKind: 'topic',
        topic: {
            id: 'technology',
            title: 'Technology',
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/technology/innovation': {
        routeKind: 'topic',
        topic: {
            id: 'technology/innovation',
            title: 'Innovation',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/technology/science': {
        routeKind: 'topic',
        topic: {
            id: 'technology/science',
            title: 'Science',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/news/environment': {
        routeKind: 'topic',
        topic: {
            id: 'news/environment',
            title: 'Environment',
            parent: { id: 'news', title: 'News', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/west-coast-eagles': {
        routeKind: 'topic',
        topic: {
            id: 'sport/west-coast-eagles',
            title: 'West Coast Eagles',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/fremantle-dockers': {
        routeKind: 'topic',
        topic: {
            id: 'sport/fremantle-dockers',
            title: 'Fremantle Dockers',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/wafl': {
        routeKind: 'topic',
        topic: {
            id: 'sport/wafl',
            title: 'WAFL',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/aflw': {
        routeKind: 'topic',
        topic: {
            id: 'sport/aflw',
            title: 'AFLW',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'AFLW',
        },
        publicationKind: 'article',
    },
    '/sport/perth-scorchers': {
        routeKind: 'topic',
        topic: {
            id: 'sport/perth-scorchers',
            title: 'Perth Scorchers',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/the-ashes': {
        routeKind: 'topic',
        topic: {
            id: 'sport/the-ashes',
            title: 'The Ashes',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/big-bash-league': {
        routeKind: 'topic',
        topic: {
            id: 'sport/big-bash-league',
            title: 'Big Bash League',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/indian-premier-league': {
        routeKind: 'topic',
        topic: {
            id: 'sport/indian-premier-league',
            title: 'Indian Premier League',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/australian-cricket-team': {
        routeKind: 'topic',
        topic: {
            id: 'sport/australian-cricket-team',
            title: 'Australian Cricket Team',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/womens-cricket': {
        routeKind: 'topic',
        topic: {
            id: 'sport/womens-cricket',
            title: 'Women’s Cricket',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/a-league': {
        routeKind: 'topic',
        topic: {
            id: 'sport/a-league',
            title: 'A-League',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/perth-glory': {
        routeKind: 'topic',
        topic: {
            id: 'sport/perth-glory',
            title: 'Perth Glory',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/english-premier-league': {
        routeKind: 'topic',
        topic: {
            id: 'sport/english-premier-league',
            title: 'English Premier League',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/basketball': {
        routeKind: 'topic',
        topic: {
            id: 'sport/basketball',
            title: 'Basketball',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/perth-wildcats': {
        routeKind: 'topic',
        topic: {
            id: 'sport/perth-wildcats',
            title: 'Perth Wildcats',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/rugby-league': {
        routeKind: 'topic',
        topic: {
            id: 'sport/rugby-league',
            title: 'Rugby League',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/state-of-origin': {
        routeKind: 'topic',
        topic: {
            id: 'sport/state-of-origin',
            title: 'State of Origin',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/rugby-union': {
        routeKind: 'topic',
        topic: {
            id: 'sport/rugby-union',
            title: 'Rugby Union',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/tennis': {
        routeKind: 'topic',
        topic: {
            id: 'sport/tennis',
            title: 'Tennis',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/horse-racing': {
        routeKind: 'topic',
        topic: {
            id: 'sport/horse-racing',
            title: 'Racing',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/golf': {
        routeKind: 'topic',
        topic: {
            id: 'sport/golf',
            title: 'Golf',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/motorsport': {
        routeKind: 'topic',
        topic: {
            id: 'sport/motorsport',
            title: 'Motorsport',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/f1': {
        routeKind: 'topic',
        topic: {
            id: 'sport/f1',
            title: 'Formula 1',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/v8': {
        routeKind: 'topic',
        topic: {
            id: 'sport/v8',
            title: 'V8 Supercars',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/moto-gp': {
        routeKind: 'topic',
        topic: {
            id: 'sport/moto-gp',
            title: 'Moto GP',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/boxing': {
        routeKind: 'topic',
        topic: {
            id: 'sport/boxing',
            title: 'Boxing',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/mixed-martial-arts': {
        routeKind: 'topic',
        topic: {
            id: 'sport/mixed-martial-arts',
            title: 'Mixed Martial Arts',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/netball': {
        routeKind: 'topic',
        topic: {
            id: 'sport/netball',
            title: 'Netball',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/west-coast-fever': {
        routeKind: 'topic',
        topic: {
            id: 'sport/west-coast-fever',
            title: 'West Coast Fever',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/cycling': {
        routeKind: 'topic',
        topic: {
            id: 'sport/cycling',
            title: 'Cycling',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/the-game-afl-podcast': {
        routeKind: 'topic',
        topic: {
            id: 'sport/the-game-afl-podcast',
            title: 'The Game: AFL with Duff and Quarters',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/australian-open': {
        routeKind: 'topic',
        topic: {
            id: 'sport/australian-open',
            title: 'Australian Open',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/commonwealth-games': {
        routeKind: 'topic',
        topic: {
            id: 'sport/commonwealth-games',
            title: 'Commonwealth Games',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/entertainment': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment',
            title: 'Entertainment',
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/entertainment/confidential': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/confidential',
            title: 'Confidential',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/entertainment/movies': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/movies',
            title: 'Movies',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/entertainment/best-australian-short-film': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/best-australian-short-film',
            title: 'Best Australian Short Film',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/entertainment/tv': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/tv',
            title: 'TV',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/entertainment/music': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/music',
            title: 'Music',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/entertainment/books': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/books',
            title: 'Books',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/entertainment/gallery': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment',
            title: 'Entertainment',
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'gallery',
    },
    '/entertainment/competitions': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/competitions',
            title: 'Competitions & Puzzles',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/business': {
        routeKind: 'topic',
        topic: {
            id: 'business',
            title: 'Business',
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/business/economy': {
        routeKind: 'topic',
        topic: {
            id: 'business/economy',
            title: 'Economy',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/business/markets': {
        routeKind: 'topic',
        topic: {
            id: 'business/markets',
            title: 'Markets',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/business/property': {
        routeKind: 'topic',
        topic: {
            id: 'business/property',
            title: 'Property',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/business/commercial-property': {
        routeKind: 'topic',
        topic: {
            id: 'business/commercial-property',
            title: 'Commercial Property',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/business/workplace-matters': {
        routeKind: 'topic',
        topic: {
            id: 'business/workplace-matters',
            title: 'Workplace Matters',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/lifestyle': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle',
            title: 'Lifestyle',
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/lifestyle/food': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/food',
            title: 'Food',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/lifestyle/health-wellbeing': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/health-wellbeing',
            title: 'Health & Wellbeing',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/lifestyle/real-estate': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/real-estate',
            title: 'Real Estate',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/lifestyle/personal-finance': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/personal-finance',
            title: 'Personal Finance',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/lifestyle/parenting': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/parenting',
            title: 'Parenting',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/lifestyle/fashion': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/fashion',
            title: 'Fashion',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/lifestyle/gallery': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle',
            title: 'Lifestyle',
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'gallery',
    },
    '/travel': {
        routeKind: 'topic',
        topic: { id: 'travel', title: 'Travel', metadata: {}, seoTitle: '' },
        publicationKind: 'article',
    },
    '/travel/caravan-camping': {
        routeKind: 'topic',
        topic: {
            id: 'travel/caravan-camping',
            title: 'Caravan & Camping',
            parent: {
                id: 'travel',
                title: 'Travel',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/lifestyle/relationships': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/relationships',
            title: 'Relationships',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/opinion': {
        routeKind: 'topic',
        topic: { id: 'opinion', title: 'Opinion', metadata: {}, seoTitle: '' },
        publicationKind: 'article',
    },
    '/opinion/joe-spagnolo': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/joe-spagnolo',
            title: 'Joe Spagnolo',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/opinion/heidi-anderson': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/heidi-anderson',
            title: 'Heidi Anderson',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/opinion/tom-percy': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/tom-percy',
            title: 'Tom Percy',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/opinion/nat-locke': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/nat-locke',
            title: 'Nat Locke',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/opinion/greg-smith/gallery': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/greg-smith',
            title: 'Greg Smith',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'gallery',
    },
    '/news/wa': {
        routeKind: 'topic',
        topic: {
            id: 'news/wa',
            title: 'WA News',
            parent: { id: 'news', title: 'News', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/western-force': {
        routeKind: 'topic',
        topic: {
            id: 'sport/western-force',
            title: 'Western Force',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/wallabies': {
        routeKind: 'topic',
        topic: {
            id: 'sport/wallabies',
            title: 'Wallabies',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/superbikes': {
        routeKind: 'topic',
        topic: {
            id: 'sport/superbikes',
            title: 'Superbikes',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/indycar': {
        routeKind: 'topic',
        topic: {
            id: 'sport/indycar',
            title: 'IndyCar',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/nascar': {
        routeKind: 'topic',
        topic: {
            id: 'sport/nascar',
            title: 'NASCAR',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/international-races': {
        routeKind: 'topic',
        topic: {
            id: 'sport/international-races',
            title: 'International Races',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/wa-races': {
        routeKind: 'topic',
        topic: {
            id: 'sport/wa-races',
            title: 'Western Australia Races',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/vic-races': {
        routeKind: 'topic',
        topic: {
            id: 'sport/vic-races',
            title: 'Victoria Races',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/qld-races': {
        routeKind: 'topic',
        topic: {
            id: 'sport/qld-races',
            title: 'Queensland Races',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/nsw-races': {
        routeKind: 'topic',
        topic: {
            id: 'sport/nsw-races',
            title: 'New South Wales Races',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/sport/sa-races': {
        routeKind: 'topic',
        topic: {
            id: 'sport/sa-races',
            title: 'South Australia Races',
            parent: { id: 'sport', title: 'Sport', metadata: {}, seoTitle: '' },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
    '/shop-now': {
        routeKind: 'topic',
        topic: {
            id: 'shop-now',
            title: 'Shop Now',
            parent: {
                id: 'shop-now',
                title: 'Shop Now',
                metadata: {},
                seoTitle: '',
            },
            metadata: {},
            seoTitle: '',
        },
        publicationKind: 'article',
    },
}

export const navigation: PerthNowNavItem[] = [
    {
        name: 'News',
        link: '/news',
        section: PerthNowSections.news,
        subNavLinks: [
            {
                name: 'Breaking News',
                link: '/news/breaking-news',
            },
            {
                name: 'Western Australia',
                link: '/news/wa',
                subNavLinks: [
                    {
                        name: 'Perth Traffic',
                        link: '/news/perth-traffic',
                    },
                    {
                        name: 'Perth Beaches',
                        link: '/news/western-australia-beaches',
                    },
                    {
                        name: 'Bushfire Alerts',
                        link: '/news/bushfire-alerts',
                    },
                ],
            },
            {
                name: 'National',
                link: '/news/national',
            },
            {
                name: 'World',
                link: '/news/world',
            },
            {
                name: 'Technology',
                footerName: 'Technology',
                link: '/technology',
                subNavLinks: [
                    {
                        name: 'Innovation',
                        link: '/technology/innovation',
                    },
                    {
                        name: 'Science',
                        link: '/technology/science',
                    },
                    {
                        name: 'Environment',
                        link: '/news/environment',
                    },
                ],
            },
            {
                name: 'Opinion',
                link: '/opinion',
                subNavLinks: [
                    {
                        name: 'Joe Spagnolo',
                        link: '/opinion/joe-spagnolo',
                    },
                    {
                        name: 'Heidi Anderson',
                        link: '/opinion/heidi-anderson',
                    },
                    {
                        name: 'Tom Percy',
                        link: '/opinion/tom-percy',
                    },
                    {
                        name: 'Nat Locke',
                        link: '/opinion/nat-locke',
                    },
                    {
                        name: 'Smithy Cartoons',
                        link: '/opinion/greg-smith/gallery',
                    },
                ],
            },
            {
                name: 'Weather',
                link: '/news/weather',
            },
        ],
    },
    {
        name: 'Sport',
        link: '/sport',
        section: PerthNowSections.sport,
        subNavLinks: [
            {
                name: 'AFL',
                link: '/sport/afl',
                subNavLinks: [
                    {
                        name: 'West Coast',
                        footerName: 'West Coast Eagles',
                        link: '/sport/west-coast-eagles',
                    },
                    {
                        name: 'Fremantle',
                        footerName: 'Fremantle Dockers',
                        link: '/sport/fremantle-dockers',
                    },
                    {
                        name: 'WAFL',
                        link: '/sport/wafl',
                    },
                    {
                        name: 'AFLW',
                        link: '/sport/aflw',
                    },
                ],
            },
            {
                name: 'Cricket',
                link: '/sport/cricket',
                subNavLinks: [
                    {
                        name: 'Big Bash',
                        link: '/sport/big-bash-league',
                        hideFromFooter: true,
                    },
                    {
                        name: 'Scorchers',
                        link: '/sport/perth-scorchers',
                        hideFromFooter: true,
                    },
                ],
            },
            {
                name: 'Soccer',
                link: '/sport/soccer',
                subNavLinks: [
                    {
                        name: 'A-League',
                        link: '/sport/a-league',
                        hideFromFooter: true,
                    },
                    {
                        name: 'Glory',
                        footerName: 'Perth Glory',
                        link: '/sport/perth-glory',
                    },
                    {
                        name: 'English Premier League',
                        link: '/sport/english-premier-league',
                        hideFromFooter: true,
                    },
                ],
            },
            {
                name: 'Basketball',
                link: '/sport/basketball',
                subNavLinks: [
                    {
                        name: 'Wildcats',
                        footerName: 'Perth Wildcats',
                        link: '/sport/perth-wildcats',
                    },
                ],
            },
            {
                name: 'Tennis',
                link: '/sport/tennis',
            },
            {
                name: 'NRL',
                link: '/sport/rugby-league',
                subNavLinks: [
                    {
                        name: 'State of Origin',
                        link: '/sport/state-of-origin',
                    },
                ],
            },
            {
                name: 'Rugby',
                link: '/sport/rugby',
                subNavLinks: [
                    {
                        name: 'Super',
                        footerName: 'Super Rugby',
                        link: '/sport/rugby-union',
                        hideFromFooter: true,
                    },
                    {
                        name: 'Force',
                        footerName: 'Western Force',
                        link: '/sport/western-force',
                    },
                    {
                        name: 'Wallabies',
                        link: '/sport/wallabies',
                        hideFromFooter: true,
                    },
                ],
            },
            {
                name: 'Motor',
                link: '/sport/motorsport',
                subNavLinks: [
                    {
                        name: 'F1',
                        footerName: 'Formula 1',
                        link: '/sport/f1',
                        hideFromFooter: true,
                    },
                    {
                        name: 'V8 Supercars',
                        footerName: 'V8 Supercars',
                        link: '/sport/v8',
                        hideFromFooter: true,
                    },
                    {
                        name: 'MotoGP',
                        link: '/sport/moto-gp',
                        hideFromFooter: true,
                    },
                    {
                        name: 'Superbikes',
                        link: '/sport/superbikes',
                        hideFromFooter: true,
                    },
                    {
                        name: 'IndyCar',
                        link: '/sport/indycar',
                        hideFromFooter: true,
                    },
                    {
                        name: 'NASCAR',
                        link: '/sport/nascar',
                        hideFromFooter: true,
                    },
                ],
            },
            {
                name: 'Racing',
                link: '/sport/horse-racing',
                subNavLinks: [
                    {
                        name: 'International Races',
                        link: '/sport/international-races',
                        hideFromFooter: true,
                    },
                    {
                        name: 'WA',
                        link: '/sport/wa-races',
                        hideFromFooter: true,
                    },
                    {
                        name: 'VIC',
                        link: '/sport/vic-races',
                        hideFromFooter: true,
                    },
                    {
                        name: 'QLD',
                        link: '/sport/qld-races',
                        hideFromFooter: true,
                    },
                    {
                        name: 'NSW',
                        link: '/sport/nsw-races',
                        hideFromFooter: true,
                    },
                    {
                        name: 'SA',
                        link: '/sport/sa-races',
                        hideFromFooter: true,
                    },
                ],
            },
            {
                name: 'Boxing',
                link: '/sport/boxing',
                hideFromMainNav: true,
            },
            {
                name: 'MMA',
                footerName: 'Mixed Martial Arts',
                link: '/sport/mixed-martial-arts',
            },
            {
                name: 'Golf',
                link: '/sport/golf',
            },
            {
                name: 'Netball',
                link: '/sport/netball',
                subNavLinks: [
                    {
                        name: 'Fever',
                        link: '/sport/west-coast-fever',
                        hideFromFooter: true,
                    },
                ],
            },
            {
                name: 'Cycling',
                link: '/sport/cycling',
            },
        ],
    },
    {
        name: 'Entertainment',
        link: '/entertainment',
        section: PerthNowSections.entertainment,
        subNavLinks: [
            {
                name: 'Confidential',
                link: '/entertainment/confidential',
            },
            {
                name: 'Movies',
                link: '/entertainment/movies',
            },
            {
                name: 'Best Short Film',
                link: '/entertainment/best-australian-short-film',
            },
            {
                name: 'Television',
                link: '/entertainment/tv',
            },
            {
                name: 'Music',
                link: '/entertainment/music',
            },
            {
                name: 'Reviews',
                link: '/entertainment/reviews',
            },
            {
                name: 'Books',
                link: '/entertainment/books',
            },
            {
                name: 'Competitions',
                link: '/entertainment/competitions',
            },
        ],
    },
    {
        name: 'Business',
        link: '/business',
        section: PerthNowSections.business,
        subNavLinks: [
            {
                name: 'Breaking News',
                link: '/business/breaking-news',
            },
            {
                name: 'Economy',
                link: '/business/economy',
            },
            {
                name: 'Markets',
                link: '/business/markets',
            },
            {
                name: 'Property',
                link: '/business/property',
            },
            {
                name: 'Commercial Property',
                link: '/business/commercial-property',
            },
            {
                name: 'Workplace Matters',
                link: '/business/workplace-matters',
            },
        ],
    },
    {
        name: 'Lifestyle',
        link: '/lifestyle',
        section: PerthNowSections.lifestyle,
        subNavLinks: [
            {
                name: 'Food',
                link: '/lifestyle/food',
            },
            {
                name: 'Personal Finance',
                link: '/lifestyle/personal-finance',
            },
            {
                name: 'Health',
                link: '/lifestyle/health-wellbeing',
            },
            {
                name: 'Parenting',
                link: '/lifestyle/parenting',
            },
            {
                name: 'Fashion',
                link: '/lifestyle/fashion',
            },
            {
                name: 'Travel',
                link: '/travel',
                subNavLinks: [
                    {
                        name: 'Caravan & Camping',
                        link: '/travel/caravan-camping',
                        hideFromMainNav: true,
                    },
                ],
            },
            {
                name: 'Home & Garden',
                link: '/lifestyle/home-garden',
            },
            {
                name: 'Relationships',
                link: '/lifestyle/relationships',
            },
            {
                name: 'Stars',
                link: '/lifestyle/horoscopes',
            },
            {
                name: 'Real Estate',
                link: '/lifestyle/real-estate',
            },
            {
                name: 'Galleries',
                link: '/lifestyle/gallery',
                hideFromMainNav: true,
            },
            {
                name: 'HUH?',
                link: '/lifestyle/have-you-heard',
            },
        ],
    },
    {
        name: 'Local News',
        link: '/local-news',
        hideFromFooter: true,
        section: PerthNowSections['local-news'],
        feature: 'cng-to-perth-now-rebrand',
        subNavLinks: [
            {
                name: 'North',
                link: '/local-news/north',
                subNavLinks: [
                    {
                        name: 'Wanneroo',
                        link: '/local-news/perthnow-wanneroo',
                    },
                    {
                        name: 'Joondalup',
                        link: '/local-news/perthnow-joondalup',
                    },
                    { name: 'Stirling', link: '/local-news/perthnow-stirling' },
                    {
                        name: 'Digital Editions',
                        link: '/editions',
                        forceInternalSSR: true,
                    },
                ],
            },
            {
                name: 'Central',
                link: '/local-news/central',
                subNavLinks: [
                    {
                        name: 'Western Suburbs',
                        link: '/local-news/perthnow-western-suburbs',
                    },
                    {
                        name: 'Central',
                        link: '/local-news/perthnow-central',
                    },
                    {
                        name: 'Southern',
                        link: '/local-news/perthnow-southern',
                    },
                    {
                        name: 'Digital Editions',
                        link: '/editions',
                        forceInternalSSR: true,
                    },
                ],
            },
            {
                name: 'South',
                link: '/local-news/south',
                subNavLinks: [
                    {
                        name: 'Fremantle',
                        link: '/local-news/perthnow-fremantle',
                    },
                    {
                        name: 'Melville',
                        link: '/local-news/perthnow-melville',
                    },
                    {
                        name: 'Canning',
                        link: '/local-news/perthnow-canning',
                    },
                    {
                        name: 'Cockburn',
                        link: '/local-news/perthnow-cockburn',
                    },
                    {
                        name: 'Digital Editions',
                        link: '/editions',
                        forceInternalSSR: true,
                    },
                ],
            },
            {
                name: 'Mandurah',
                link: '/community-news/mandurah-times',
            },
            {
                name: 'Competitions',
                link: '/local-news/competitions',
            },
            {
                name: 'Find My Paper',
                link: '/local-news/find-my-paper',
            },
            {
                name: 'Digital Editions',
                link: '/editions',
                forceInternalSSR: true,
            },
        ],
    },
    {
        name: 'Shop Now',
        link: '/shop-now',
        section: PerthNowSections['shop-now'],
        subNavLinks: [
            {
                name: 'Fashion',
                link: '/shop-now/shopnow-fashion',
            },
            {
                name: 'Beauty',
                link: '/shop-now/shopnow-beauty',
            },
            {
                name: 'Home & Garden',
                link: '/shop-now/shopnow-home-garden',
            },
            {
                name: 'Electronics',
                link: '/shop-now/shopnow-electronics',
            },
            {
                name: 'Travel',
                link: '/shop-now/shopnow-travel',
            },
            {
                name: 'Lifestyle',
                link: '/shop-now/shopnow-lifestyle',
            },
        ],
    },
    {
        name: 'Read your local paper',
        smallName: 'Local paper',
        link: '/editions?utm_source=PerthNow&utm_medium=Referral&utm_campaign=digital-edition&utm_term=digitalprinteditions&utm_content=header-nav',
        section: PerthNowSections['read-your-local-paper'],
        isFilled: true,
        forceInternalSSR: true,
        hideFromAppNav: true,
    },
]

// TODO: Remove when Taxonomy is updated
export const navigationOriginalTaxonomyNGN: PerthNowNavItem[] = [
    {
        name: 'WA News',
        link: '/news/wa',
        section: PerthNowSections['wa-news'],
        subNavLinks: [
            {
                name: 'All WA News',
                link: '/news/wa',
            },
            {
                name: 'Perth',
                link: '/local-news',
            },
            {
                name: 'What’s On',
                link: '/business/west-business-events',
            },
            {
                name: 'Social',
                link: '/lifestyle/social-pictures',
            },
            {
                name: 'Traffic',
                link: '/news/perth-traffic',
            },
            {
                name: 'Weather',
                link: '/news/weather',
            },
        ],
    },
    {
        name: 'Entertainment',
        link: '/entertainment',
        section: PerthNowSections.entertainment,
        subNavLinks: [
            {
                name: 'All Entertainment',
                link: '/entertainment',
            },
            {
                name: 'Celebrity Gossip',
                link: '/entertainment/celebrity',
            },
            {
                name: 'Royals',
                link: '/news/royal-family',
            },
            {
                name: 'Influencers',
                link: '/entertainment/confidential',
            },
            {
                name: 'Movies',
                link: '/entertainment/movies',
            },
            {
                name: 'TV',
                link: '/entertainment/tv',
            },
            {
                name: 'Music',
                link: '/entertainment/music',
            },
        ],
    },
    {
        name: 'News',
        link: '/news',
        section: PerthNowSections.news,
        subNavLinks: [
            {
                name: 'All News',
                link: '/news',
            },
            {
                name: 'Australia',
                link: '/news/australia',
            },
            {
                name: 'Crime',
                link: '/news/crime',
            },
            {
                name: 'World',
                link: '/news/world',
            },
            {
                name: 'Business',
                link: '/business',
            },
            {
                name: 'Opinion',
                link: '/news/opinion',
            },
        ],
    },
    {
        name: 'Food',
        link: '/lifestyle/food',
        section: PerthNowSections.lifestyle,
        subNavLinks: [
            {
                name: 'All Food',
                link: '/lifestyle/food',
            },
            {
                name: 'Best Eats',
                link: '/lifestyle/restaurant-reviews',
            },
            {
                name: 'Tinnie of the Week',
                link: '/lifestyle/tinnie-of-the-week',
            },
            {
                name: 'Drink',
                link: '/lifestyle/drink',
            },
        ],
    },

    {
        name: 'Lifestyle',
        link: '/lifestyle',
        section: PerthNowSections.lifestyle,
        subNavLinks: [
            {
                name: 'All Life',
                link: '/lifestyle',
            },
            {
                name: 'Cash Confessions',
                link: '/lifestyle/cash-confessions',
            },
            {
                name: 'Lotto',
                link: '/news/lotto',
            },
            {
                name: 'Love & Relationships',
                link: '/lifestyle/relationships',
            },
            {
                name: 'Real Estate',
                link: '/lifestyle/real-estate',
            },
            {
                name: 'Personal Finance',
                link: '/lifestyle/personal-finance',
            },
            {
                name: 'Home & Garden',
                link: '/lifestyle/garden',
            },
            {
                name: 'Family Life',
                link: '/lifestyle/parenting',
            },
            {
                name: 'Travel',
                link: '/travel',
            },
            {
                name: 'Beauty & Style',
                link: '/lifestyle/beauty',
            },
            {
                name: 'Health & Fitness',
                link: '/lifestyle/healthmedicine',
            },
            {
                name: 'Horoscopes',
                link: '/lifestyle/horoscopes',
            },
        ],
    },
    {
        name: 'Sport',
        link: '/sport',
        section: PerthNowSections.sport,
        subNavLinks: [
            {
                name: 'All Sport',
                link: '/sport',
            },
            {
                name: 'AFL',
                link: '/sport/afl',
            },
            {
                name: 'Cricket',
                link: '/sport/cricket',
            },
            {
                name: 'Soccer',
                link: '/sport/soccer',
            },
            {
                name: 'Basketball',
                link: '/sport/basketball',
            },
            {
                name: 'Tennis',
                link: '/sport/tennis',
            },
            {
                name: 'NRL',
                link: '/sport/rugby-league',
            },
            {
                name: 'Rugby',
                link: '/sport/rugby',
            },
            {
                name: 'Motorsport',
                link: '/sport/motorsport',
            },
            {
                name: 'Racing',
                link: '/sport/horse-racing',
            },
            {
                name: 'MMA',
                footerName: 'Mixed Martial Arts',
                link: '/sport/mixed-martial-arts',
            },
            {
                name: 'Golf',
                link: '/sport/golf',
            },
            {
                name: 'Netball',
                link: '/sport/netball',
            },
            {
                name: 'Cycling',
                link: '/sport/cycling',
            },
        ],
    },
    {
        name: 'ShopNow',
        link: '/shop-now',
        section: PerthNowSections['shop-now'],
        subNavLinks: [
            {
                name: 'All ShopNow',
                link: '/shop-now',
            },
            {
                name: 'Fashion',
                link: '/shop-now/shopnow-fashion',
            },
            {
                name: 'Beauty',
                link: '/shop-now/shopnow-beauty',
            },
            {
                name: 'Home & Garden',
                link: '/shop-now/shopnow-home-garden',
            },
            {
                name: 'Electronics',
                link: '/shop-now/shopnow-electronics',
            },
            {
                name: 'Travel',
                link: '/shop-now/shopnow-travel',
            },
            {
                name: 'Lifestyle',
                link: '/shop-now/shopnow-lifestyle',
            },
        ],
    },
]

export const navigationNewTaxonomyNGN: PerthNowNavItem[] = [
    {
        name: 'WA News',
        link: '/wa',
        section: PerthNowSections['wa-news'],
        subNavLinks: [
            {
                name: 'All WA News',
                link: '/wa',
            },
            {
                name: 'Perth',
                link: '/wa/perth',
            },
            {
                name: 'What’s On',
                link: '/wa/whats-on',
            },
            {
                name: 'Social',
                link: '/wa/social',
            },
            {
                name: 'Traffic',
                link: '/wa/traffic',
            },
            {
                name: 'Weather',
                link: '/wa/weather',
            },
        ],
    },
    {
        name: 'Entertainment',
        link: '/entertainment',
        section: PerthNowSections.entertainment,
        subNavLinks: [
            {
                name: 'All Entertainment',
                link: '/entertainment',
            },
            {
                name: 'Celebrity Gossip',
                link: '/entertainment/celebrity',
            },
            {
                name: 'Royals',
                link: '/entertainment/royals',
            },
            {
                name: 'Influencers',
                link: '/entertainment/influencers',
            },
            {
                name: 'Movies',
                link: '/entertainment/movies',
            },
            {
                name: 'TV',
                link: '/entertainment/tv',
            },
            {
                name: 'Music',
                link: '/entertainment/music',
            },
        ],
    },
    {
        name: 'News',
        link: '/news',
        section: PerthNowSections.news,
        subNavLinks: [
            {
                name: 'All News',
                link: '/news',
            },
            {
                name: 'Australia',
                link: '/news/australia',
            },
            {
                name: 'Crime',
                link: '/news/crime',
            },
            {
                name: 'World',
                link: '/news/world',
            },
            {
                name: 'Business',
                link: '/news/business',
            },
            {
                name: 'Opinion',
                link: '/news/opinion',
            },
        ],
    },
    {
        name: 'Food',
        link: '/food',
        section: PerthNowSections.business,
        subNavLinks: [
            {
                name: 'All Food',
                link: '/food',
            },
            {
                name: 'Best Eats',
                link: '/food/best-eats',
            },
            {
                name: 'Tinnie of the Week',
                link: '/food/tinnie-of-the-week',
            },
            {
                name: 'Drink',
                link: '/food/drink',
            },
        ],
    },
    {
        name: 'Lifestyle',
        link: '/lifestyle',
        section: PerthNowSections.lifestyle,
        subNavLinks: [
            {
                name: 'All Lifestyle',
                link: '/lifestyle',
            },
            {
                name: 'Cash Confessions',
                link: '/lifestyle/cash-confessions',
            },
            {
                name: 'Lotto',
                link: '/lifestyle/lotto',
            },
            {
                name: 'Love & Relationships',
                link: '/lifestyle/love-relationships',
            },
            {
                name: 'Real Estate',
                link: '/lifestyle/real-estate',
            },
            {
                name: 'Personal Finance',
                link: '/lifestyle/personal-finance',
            },
            {
                name: 'Home & Garden',
                link: '/lifestyle/home-garden',
            },
            {
                name: 'Family Life',
                link: '/lifestyle/family-life',
            },
            {
                name: 'Travel',
                link: '/lifestyle/travel',
            },
            {
                name: 'Beauty & Style',
                link: '/lifestyle/beauty-style',
            },
            {
                name: 'Health & Fitness',
                link: '/lifestyle/health-fitness',
            },
            {
                name: 'Horoscopes',
                link: '/lifestyle/horoscopes',
            },
        ],
    },
    {
        name: 'Sport',
        link: '/sport',
        section: PerthNowSections.sport,
        subNavLinks: [
            {
                name: 'All Sport',
                link: '/sport',
            },
            {
                name: 'AFL',
                link: '/sport/afl',
            },
            {
                name: 'Cricket',
                link: '/sport/cricket',
            },
            {
                name: 'Soccer',
                link: '/sport/soccer',
            },
            {
                name: 'Basketball',
                link: '/sport/basketball',
            },
            {
                name: 'Tennis',
                link: '/sport/tennis',
            },
            {
                name: 'NRL',
                link: '/sport/rugby-league',
            },
            {
                name: 'Rugby',
                link: '/sport/rugby-union',
            },
            {
                name: 'Motorsport',
                link: '/sport/motorsport',
            },
            {
                name: 'Racing',
                link: '/sport/horse-racing',
            },
            {
                name: 'MMA',
                link: '/sport/mixed-martial-arts',
            },
            {
                name: 'Golf',
                link: '/sport/golf',
            },
            {
                name: 'Netball',
                link: '/sport/netball',
            },
            {
                name: 'Cycling',
                link: '/sport/cycling',
            },
        ],
    },
    {
        name: 'ShopNow',
        link: '/shop-now',
        section: PerthNowSections['shop-now'],
        subNavLinks: [
            {
                name: 'All ShopNow',
                link: '/shop-now',
            },
            {
                name: 'Fashion',
                link: '/shop-now/shopnow-fashion',
            },
            {
                name: 'Beauty',
                link: '/shop-now/shopnow-beauty',
            },
            {
                name: 'Home & Garden',
                link: '/shop-now/shopnow-home-garden',
            },
            {
                name: 'Electronics',
                link: '/shop-now/shopnow-electronics',
            },
            {
                name: 'Travel',
                link: '/shop-now/shopnow-travel',
            },
            {
                name: 'Lifestyle',
                link: '/shop-now/shopnow-lifestyle',
            },
        ],
    },
]

export const navigationMobileTabletNGN: PerthNowNavItem[] = [
    {
        name: 'Perth',
        link: '/local-news',
        section: PerthNowSections['local-news'],
    },
    {
        name: 'Celebrity Gossip',
        link: '/entertainment/celebrity',
        section: PerthNowSections.entertainment,
    },
    {
        name: 'Australia',
        link: '/news/australia',
        section: PerthNowSections['over-east'],
    },
    {
        name: 'Best Eats',
        link: '/lifestyle/restaurant-reviews',
        section: PerthNowSections['best-eats'],
    },
    {
        name: 'Eagles',
        link: '/sport/west-coast-eagles',
        section: PerthNowSections['west-coast'],
    },
    {
        name: 'Dockers',
        link: '/sport/fremantle-dockers',
        section: PerthNowSections.fremantle,
    },
    {
        name: 'Crime',
        link: '/news/crime',
        section: PerthNowSections.crime,
    },
    {
        name: 'Weather',
        link: '/news/weather',
        section: PerthNowSections.weather,
    },
    {
        name: 'Traffic',
        link: '/news/perth-traffic',
        section: PerthNowSections.traffic,
    },
    {
        name: 'Lotto',
        link: '/news/lotto',
        section: PerthNowSections.lotto,
    },
    {
        name: 'ShopNow',
        link: '/shop-now',
        section: PerthNowSections['shop-now'],
    },
]

export const navigationMobileTabletNewTaxonomyNGN: PerthNowNavItem[] = [
    {
        name: 'Perth',
        link: '/wa/perth',
        section: PerthNowSections['local-news'],
    },
    {
        name: 'Celebrity Gossip',
        link: '/entertainment/celebrity',
        section: PerthNowSections.entertainment,
    },
    {
        name: 'Australia',
        link: '/news/australia',
        section: PerthNowSections['over-east'],
    },
    {
        name: 'Best Eats',
        link: '/food/best-eats',
        section: PerthNowSections['best-eats'],
    },
    {
        name: 'Eagles',
        link: '/sport/afl/west-coast-eagles',
        section: PerthNowSections['west-coast'],
    },
    {
        name: 'Dockers',
        link: '/sport/afl/fremantle-dockers',
        section: PerthNowSections.fremantle,
    },
    {
        name: 'Crime',
        link: '/news/crime',
        section: PerthNowSections.crime,
    },
    {
        name: 'Weather',
        link: '/wa/weather',
        section: PerthNowSections.weather,
    },
    {
        name: 'Traffic',
        link: '/wa/traffic',
        section: PerthNowSections.traffic,
    },
    {
        name: 'Lotto',
        link: '/lifestyle/lotto',
        section: PerthNowSections.lotto,
    },
    {
        name: 'ShopNow',
        link: '/shop-now',
        section: PerthNowSections['shop-now'],
    },
]

import React from 'react'

export interface IconShareStoryNGNProps {
    shareFillColor?: string
}
export const IconShareStoryNGN: React.FC<IconShareStoryNGNProps> = ({
    shareFillColor = '#DD1466',
}) => (
    <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_11554_6093)">
            <path
                d="M5.72668 9.94434L10.28 12.5977M10.2734 5.27767L5.72668 7.931M14 4.271C14 5.37557 13.1046 6.271 12 6.271C10.8954 6.271 10 5.37557 10 4.271C10 3.16643 10.8954 2.271 12 2.271C13.1046 2.271 14 3.16643 14 4.271ZM6 8.93766C6 10.0422 5.10457 10.9377 4 10.9377C2.89543 10.9377 2 10.0422 2 8.93766C2 7.83309 2.89543 6.93766 4 6.93766C5.10457 6.93766 6 7.83309 6 8.93766ZM14 13.6043C14 14.7089 13.1046 15.6043 12 15.6043C10.8954 15.6043 10 14.7089 10 13.6043C10 12.4998 10.8954 11.6043 12 11.6043C13.1046 11.6043 14 12.4998 14 13.6043Z"
                stroke={shareFillColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_11554_6093">
                <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.937744)"
                />
            </clipPath>
        </defs>
    </svg>
)
IconShareStoryNGN.displayName = 'IconShareStoryNGN'

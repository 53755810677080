import React, { useEffect, useState, useCallback, useRef } from 'react'
import { FeatureState } from '@etrigan/feature-toggles-client'
import {
    DataLayerEventName,
    isComponentEnabled,
    AllEvents,
    NavEvent,
    FeatureConfig,
    usePageEvents,
    Features,
    DataLoaderGlobalParams,
    getTopicListing,
} from '@news-mono/web-common'
import {
    PerthNowSection,
    breakpoints,
    useWindowWidth,
} from '@news-mono/component-library'
import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'
import { NewsletterSignupEvent } from 'libs/web-common/src/events/newsletter-signup-events'
import { PrimaryNavTopic } from './PrimaryNavTopic/PrimaryNavTopic'
import { StyledContainer, StyledList } from './PNNavigation.styled'
import { useNewsletterFormInput } from '../helpers/newsletterSignUp'

export interface PNSubNavLinkNGN extends FeatureConfig {
    name: string
    link: string
    forceInternalSSR?: boolean
}
export interface PNNavLinkNGN extends FeatureConfig {
    name: string
    link: string
    section: PerthNowSection
    subNavLinks?: PNSubNavLinkNGN[]
    forceInternalSSR?: boolean
}
export interface PNNavigationProps {
    currentSection?: PerthNowSection
    navLinks: PNNavLinkNGN[]
    stickyNav: PNNavLinkNGN[]
    subscribeLink: string
    findMyPaperLink: string
    onEvent: (event: AllEvents | NavEvent | NewsletterSignupEvent) => void
    featureState: FeatureState
    services: DataLoaderGlobalParams
    isSnapshot?: boolean
}
export interface NewsletterSubscriptionFormState {
    email: string
    error: boolean
    success: boolean
    isSubmitting: boolean
    isSubscribed: string
}

export type IsOpenState = {
    [key: string]: boolean
}
export const PNMainNavIdNGN = 'pn-main-nav-ngn'

const storyOptions: {
    paging: {
        page: number
        pageSize: number
    }
    publicationKind: 'article' | 'gallery' | undefined
    topics: string[]
} = {
    paging: {
        page: 1,
        pageSize: 3,
    },
    publicationKind: 'article',
    topics: [],
}

export const PNNavigation: React.FC<PNNavigationProps> = ({
    navLinks,
    stickyNav,
    onEvent,
    currentSection,
    subscribeLink,
    findMyPaperLink,
    featureState,
    services,
    isSnapshot = false,
}) => {
    const windowWidth = useWindowWidth() ?? 0
    const isStickyMenu = windowWidth < breakpoints.lg && windowWidth > 0
    const navMetaLinks = isStickyMenu ? stickyNav : navLinks
    const filterNavLinks = useCallback(
        <T extends { feature?: Features; invertFeatureToggle?: boolean }>(
            links: T[],
        ): T[] => {
            return links.filter(({ feature, invertFeatureToggle }) => {
                return feature
                    ? isComponentEnabled(featureState, {
                          feature,
                          invertFeatureToggle,
                      })
                    : true
            })
        },
        [featureState],
    )
    const initState = filterNavLinks(navLinks).reduce((acc, { section }) => {
        acc[section] = false
        return acc
    }, {} as { [key: string]: boolean })

    const navigationRef = useRef<HTMLDivElement>(null)
    const [isClickTarget, setIsClickTarget] = useState(false)
    const [, setLastOpenedItem] = useState<string | undefined>()
    const [impression, setImpression] = useState(false)
    const [isOpen, setIsOpen] = useState<IsOpenState>(() => initState)
    const [latestStories, setLatestStories] = useState<{
        [key: string]: ListPublicationV4DTO[]
    }>({})
    const formHook = useNewsletterFormInput(onEvent, 'mega-menu')

    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    const { recaptureSiteKey = '' } = services?.config || {}

    usePageEvents(() => {
        setImpression(true)
    }, ['page-load-complete'])

    const fetchAllLatestStories = useCallback(async () => {
        try {
            const filteredNavLinks = filterNavLinks(navMetaLinks)
            const promises = filteredNavLinks.map(({ name, link }) => {
                storyOptions.topics = [link.substring(1)]
                return getTopicListing(services, storyOptions).then(
                    (result) => ({
                        name,
                        result,
                    }),
                )
            })
            const results = await Promise.all(promises)
            const stories = results.reduce(
                (
                    acc: { [key: string]: ListPublicationV4DTO[] },
                    { name, result },
                ) => {
                    acc[name] =
                        result.documents as unknown as ListPublicationV4DTO[]
                    return acc
                },
                {},
            )

            setLatestStories(stories)
        } catch (error) {
            console.error('Error fetching latest stories:', error)
        }
    }, [services, filterNavLinks, navMetaLinks])

    useEffect(() => {
        let isMounted = true

        const fetchData = async () => {
            await fetchAllLatestStories()
            if (isMounted && impression) {
                onEvent({
                    type: DataLayerEventName.navAvailable,
                    originator: 'PNNavigation',
                    payload: {
                        navName: 'Default.PerthNow.NGNLaunch',
                    },
                })
                setImpression(false)
            }
        }

        fetchData()

        return () => {
            isMounted = false
        }
    }, [fetchAllLatestStories, impression, onEvent])

    const remapCommunityNewsSection = useCallback(
        (section: PerthNowSection): PerthNowSection => {
            return section === 'community-news' ? 'local-news' : section
        },
        [],
    )

    const toggleSubNav = useCallback(
        (section: string, status: boolean) => {
            setLastOpenedItem(
                status
                    ? remapCommunityNewsSection(section as PerthNowSection)
                    : undefined,
            )
        },
        [remapCommunityNewsSection],
    )

    const handleBlur = useCallback(
        (event: React.FocusEvent | React.MouseEvent) => {
            const type = event.type
            const currentTarget = event.currentTarget as Node
            const relatedTarget = event.relatedTarget as Node
            // Close the mega menu if the focus is outside the StyledContainer
            if (type === 'click') {
                setIsClickTarget(true)
                clearTimeout(timeoutRef.current as NodeJS.Timeout)
            } else if (
                !(
                    currentTarget &&
                    navigationRef.current &&
                    navigationRef.current.contains(relatedTarget)
                )
            ) {
                timeoutRef.current = setTimeout(() => {
                    if (!isClickTarget || !!relatedTarget) {
                        setIsOpen(initState)
                    }
                    setIsClickTarget(false)
                }, 300)
            }
        },
        [initState, isClickTarget],
    )

    return (
        <StyledContainer
            id={PNMainNavIdNGN}
            aria-label="Main navigation"
            ref={navigationRef}
        >
            <StyledList
                onBlur={handleBlur}
                onClick={handleBlur}
                onMouseLeave={() => setIsOpen(initState)}
                isSnapshot={isSnapshot}
            >
                {filterNavLinks(navMetaLinks).map(
                    (
                        { name, link, section, subNavLinks, forceInternalSSR },
                        index,
                    ) => (
                        <PrimaryNavTopic
                            key={name}
                            pos={index}
                            name={name}
                            link={link}
                            section={section}
                            subscribeLink={subscribeLink}
                            findMyPaperLink={findMyPaperLink}
                            currentSection={
                                currentSection
                                    ? remapCommunityNewsSection(currentSection)
                                    : undefined
                            }
                            subNavLinks={
                                subNavLinks &&
                                filterNavLinks<PNSubNavLinkNGN>(subNavLinks)
                            }
                            toggleSubNav={toggleSubNav}
                            onEvent={onEvent}
                            latestStories={latestStories[name] || []}
                            forceInternalSSR={forceInternalSSR}
                            formHook={formHook}
                            recaptureSiteKey={recaptureSiteKey}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            initState={initState}
                        />
                    ),
                )}
            </StyledList>
        </StyledContainer>
    )
}
